import styled from 'styled-components'
import tokens from '@veneer/tokens'
const { gray9 } = tokens.color
const { size1, size0, lineHeight2, lineHeight1 } = tokens.typography
const { size1: space1 } = tokens.layout

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;
  margin-top: 24px;
`
export const CardTitle = styled.div`
  font-family: 'Forma DJR Micro';
  font-weight: 400;
  font-size: ${size1};
  line-height: ${lineHeight2};

  color: ${gray9};
  margin-bottom: ${space1};
`
export const Container = styled.div`
  display: flex;
`

export const CardContent = styled.div`
  displat: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0.44em;
`

export const CardContentMedium = styled.div`
  font-family: 'Forma DJR Micro';
  font-size: ${size0};
  line-height: ${lineHeight1};
  font-weight: 400;
  letter-spacing: 0.04em;
  color: ${gray9};
  margin-bottom: ${space1};
`
