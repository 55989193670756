export const handleProcessingReturnStatus = (
  numOfDaysLeft,
  allNotifications,
  returnStatus,
  cancelledInRemorse
) => {
  if (cancelledInRemorse) {
    return numOfDaysLeft
      ? allNotifications.deactivating[returnStatus].inRemorsePeriod
          .returnDaysRemaining
      : allNotifications.deactivating[returnStatus].inRemorsePeriod.returnToday
  }
  return allNotifications.deactivating[returnStatus].afterRemorsePeriod
}
export const handleItemsReceivedReturnStatus = (
  allNotifications,
  cancelledInRemorse,
  returnStatus
) => {
  return cancelledInRemorse
    ? allNotifications.deactivating[returnStatus].inRemorsePeriod
    : allNotifications.deactivating[returnStatus].afterRemorsePeriod
}
export const handleCompleteStatus = (
  cancelledInRemorse,
  allNotifications,
  returnStatus
) => {
  return cancelledInRemorse
    ? allNotifications.inactive[returnStatus].inRemorsePeriod
    : allNotifications.inactive[returnStatus].afterRemorsePeriod
}
