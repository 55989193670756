export const handleNavigationPath = (url) => {
  const prefix = window.location.pathname.includes('ucde') ? '/ucde' : ''
  return `${prefix}${url}`
}

export const handleRedirect = (subscriptionId, navigation) => {
  const cancelServiceLink = `/hp-all-in-print-plan/cancellation/${subscriptionId}`

  const path = handleNavigationPath(cancelServiceLink)

  navigation ? navigation.push(path) : window.location.assign(path)
}
