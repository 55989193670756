// Subscription API doc: https://github.azc.ext.hp.com/stratus/master-bl-controller-swagger/blob/master/api/v1/hpone-bl/hpone-bl-common-components.yaml#L126
// Subscription Mock-up: https://hp-jira.external.hp.com/secure/attachment/2486657/subscriptions-mock.json

export interface Subscription {
  id: string
  blId: string
  subscriptionId: string
  friendlySubscriptionId: string
  tenantId: string
  country: string
  entities: SubscriptionEntity[]
  blManifestVersion?: string
  state?: SubscriptionState
  links?: string[] // the field doesn't exist on the Subscription API doc
}

export type SubscriptionState =
  | 'active'
  | 'inactive'
  | 'deactivating'
  | 'paused'
  | 'pending'
  | 'updating'
  | 'delinquent'

export enum SubscriptionStateEnum {
  SUBSCRIBED = 'active',
  CANCELED = 'inactive',
  CANCELING = 'deactivating',
  PAUSED = 'paused',
  PENDING = 'pending',
  UPDATING = 'updating',
  DELINQUENT = 'delinquent',
  SUSPENDED = 'suspended'
}

export interface SubscriptionCancellation {
  enabled: boolean
  locked: boolean
  type?: 'remorsePeriod' | 'paymentPeriod'
  value?: {
    validUntil?: string
  }
}

export interface SubscriptionEntity {
  entityId: string
  userId: string
  shippingAddressId: string
  state: SubscriptionState
  entityType: SubscriptionEntityType
  commerce: SubscriptionCommerce
  product: SubscriptionProduct
  source: SubscriptionSource
  cancellation: SubscriptionCancellation
  linkedEntities: LinkedEntity[]
  entityStartDate: string
  entityEndDate: string
  linkedEntityIds: string[]
  fulfillment: SubscriptionFulfillment
  entityDetails: EntityDetails
  subscriptionId: string
}
interface LinkedEntity {
  type: string
  value: string
}

export type SubscriptionEntityType =
  | 'iot-pc'
  | 'iot-printer'
  | 'instant-ink'
  | 'instant-paper'
  | 'third-party-software'
  | 'mouse'
  | 'keyboard'
  | 'pen'
  | 'carepack'
  | 'chromebook'

export enum SubscriptionEntityTypeEnum {
  PC = 'iot-pc',
  PRINTER = 'iot-printer',
  INK = 'instant-ink',
  PAPER = 'instant-paper',
  THIRD_PARTY_SOFT = 'third-party-software',
  MOUSE = 'mouse',
  KEYBOARD = 'keyboard',
  PEN = 'pen',
  CAREPACK = 'carepack',
  CHROMEBOOK = 'chromebook'
}

export interface SubscriptionCommerce {
  type: 'stratus-commerce'
  value?: {
    subscriptionId?: string // the field has a different name on the Subscription API doc
    friendlySubscriptionId?: string // the field has a different name on the Subscription API doc
  }
}

export interface SubscriptionProduct {
  type: 'magento'
  value?: {
    productSku?: string
    productType?: SubscriptionProductType
  }
}

export type SubscriptionProductType =
  | 'pc'
  | 'chromebook'
  | 'printer'
  | 'instantInk'
  | 'instantPaper'
  | 'accessories'
  | 'carepack'
  | 'googleOne'
  | 'ms365'
  | 'mouse'
  | 'keyboard'

export interface SubscriptionSource {
  type: 'magento'
  value?: {
    orderId?: string
    itemId?: string
  }
}

export interface SubscriptionFulfillment {
  type: 'sfs' | 'ob' | '3poController'
  value?: {
    fulfillmentOrderId?: string
    obId?: string
    '3poController'?: string
    shippedDate?: string
  }
}

export interface EntityDetails {
  accessoryId?: string
  pendingAssociationId?: string
  pendingAssociationState?: 'open' | 'pending' | 'inactive' | 'completed'
  serialNumber?: string
  uniqueDeviceId?: string
}

export type SubscriptionEntityEdit = {
  entityId: string
  edit: {
    apply: {
      type: string
      value: {
        optionId: string
      }
    }
  }
}

export type SubscriptionEntityCancel = {
  cancellation: {
    id: string
    apply: {
      type: string
    }
  }
}

export type SubscriptionEntityUpdate = {
  optionId: string
}
export enum SubscriptionReturnStatusEnum {
  NEW = 'new',
  CANCELED = 'cancelled',
  INITIATED = 'initiated',
  PROCESSING = 'processing',
  COMPLETE_SUCCESSFUL = 'completeSuccessful',
  COMPLETE_TIMEOUT = 'completeTimeout',
  COMPLETE_UNSUCCESSFUL = 'completeUnsuccessful',
  TIMEOUT = 'timeout',
  ITEMS_RECEIVED = 'itemReceived',
  RESOLVED = 'resolved',
  OVERDUE = 'overdue',
  SUSPENDED = 'suspended',
  NO_LONGER_ACTIVE = 'noLongeractive',
  ERROR_404 = '404'
}
