import tokens from '@veneer/tokens'
import styled from 'styled-components'
import Modal from '@veneer/core/dist/esm/scripts/modal'
const { gray12, hpBlue7 } = tokens.color
const { family0, size7, lineHeight7, size1, lineHeight1, lineHeight2, size0 } =
  tokens.typography
const { lgMin, size6, size5, size4, size2, size1: space1 } = tokens.layout
interface StyledModalProps {
  transition: number
}

export const StyledModal = styled(Modal).attrs((props: StyledModalProps) => ({
  transition: props.transition
}))`
  && {
    .vn-modal--footer {
      padding-top: 0;
      display: block;
    }

    .vn-modal__close > span > svg {
      color: ${gray12};
    }

    .vn-modal__close:focus {
      cursor: pointer;
      border: 2px solid ${hpBlue7};
      border-radius: 5px;
    }

    .vn-modal--content {
      opacity: 1;
      left: 0;
      padding: 40px;

      &.transitioning {
        animation: slide ${(props) => `${props.transition * 2}ms`} forwards;
        animation-timing-function: ease-in-out;

        @keyframes slide {
          0% {
            left: 0;
            opacity: 1;
          }
          49% {
            left: -100%;
            opacity: 0;
          }
          50% {
            left: 100%;
            opacity: 0;
          }
          100% {
            left: 0;
            opacity: 1;
          }
        }
      }

      min-width: 270px;
      @media (min-width: ${lgMin}) {
        width: max-content;
        min-height: 551px;
        max-width: 864px;
        min-width: 640px;
      }
    }
  }
`

export const ModalContainer = styled.div`
  width: 864px;
  height: 633.51px;
  top: 496px;
  left: 258px;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: ${size6};
  min-height: 411px;
  font-family: ${family0};
`

export const TopContainer = styled.div`
  width: 100%;
  gap: ${size5};
  display: flex;
  flex-direction: column;
  .title {
    font-size: ${size7};
    font-weight: 400;
    line-height: ${lineHeight7};
    letter-spacing: 0em;
    text-align: left;
  }
`
export const BottomContainer = styled.div`
  width: 100%;
  gap: ${size6};
  text-align: center;
  p {
    font-size: ${size1};
    font-weight: 400;
    line-height: ${lineHeight2};
    letter-spacing: 0em;
    text-align: center;
  }
  .custom-link {
    display: inline;
    cursor: pointer;
  }
`

export const MainContent = styled.div`
  width: 100%;
  gap: ${size5};
  display: flex;
  flex-direction: column;
`

export const AccessList = styled.div`
  width: 100%;
  min-height: 156px;
  gap: ${size4};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .access-title {
    font-size: ${size1};
    font-weight: 400;
    line-height: ${lineHeight2};
    letter-spacing: 0em;
    text-align: left;
  }
  .planlistofaccess {
    width: 100%;
    min-height: 120px;
    gap: ${size2};
    display: flex;
    flex-direction: column;
  }
`

export const ListItem = styled.div`
  display: flex;
  .icons {
    margin-right: ${size2};
  }
  .iconLabel {
    margin-right: ${size2};
  }
  p {
    font-size: ${size1};
    font-weight: 400;
    line-height: ${lineHeight2};
    letter-spacing: 0em;
    text-align: left;
  }
`

export const DeviceInfo = styled.div`
  min-height: 191px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .plan-details {
    font-size: ${size1};
    font-weight: 700;
    line-height: ${lineHeight2};
    letter-spacing: 0em;
    text-align: left;
  }
`
export const PlanStatus = styled.div`
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const DevicesList = styled.div`
  width: 100%;
  min-height: 115px;
  gap: ${size6};
  display: flex;
  align-items: center;
`

export const Thumb = styled.div`
  padding: 4px;
  min-height: 75px;
  gap: ${space1};
  img {
    width: 96px;
    height: 66.51px;
  }
`
export const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 75px;
  gap: ${space1};

  .title {
    font-size: ${size0};
    font-weight: 400;
    line-height: ${lineHeight1};
    letter-spacing: 0em;
    text-align: left;
    height: ${size6};
    padding: 4px 0px 4px 0px;
  }

  .subtitle {
    font-size: ${size0};
    font-weight: 700;
    line-height: ${lineHeight1};
    letter-spacing: 0em;
    text-align: left;
  }
`

export const StatusWithinPeriod = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-size: ${size1};
    font-weight: 400;
    line-height: ${lineHeight2};
    letter-spacing: 0em;
    text-align: left;
  }
`
