import React from 'react'
import { NotificationBarContainer } from './styles'
import { useNotification } from '../context/NotificationContext'
import InlineNotification from '@veneer/core/dist/esm/scripts/inline_notification'
import { v4 as uuidv4 } from 'uuid'
import type { NotificationProps } from '../../../../types'

const NotificationBar = ({
  statusType,
  title,
  description,
  showCloseButton = true,
  onClose,
  className,
  hideIcon,
  action
}: NotificationProps) => {
  const { setNotification } = useNotification()

  if (!statusType || !title) return null
  return (
    <NotificationBarContainer className={className}>
      <InlineNotification
        data-testid="notification-inline"
        type={statusType}
        closeButton={showCloseButton}
        title={title}
        description={description}
        hideIcon={hideIcon}
        id={uuidv4()}
        onClose={() => {
          setNotification(null)
          onClose && onClose()
        }}
        action={action}
      />
    </NotificationBarContainer>
  )
}

export { NotificationBar }
