import { JarvisAuthProvider } from '@jarvis/web-http'
import {
  Stack,
  StratusClient
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { AxiosResponse } from 'axios'
import { BillingDetails } from '@/types/instantink'
import { getAPIURLS } from '../../helpers/getUrlConfig'
import { getStack } from '../../helpers/getStack'

export class InstantInkClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(getAPIURLS(getStack(stack)).INSTANT_INK_BILLING_URL, authToken)
  }
  getInstantInkClientDetails(): Promise<AxiosResponse<BillingDetails>> {
    return this.jarvisWebHttpInstance.get({})
  }
}
