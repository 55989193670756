import Card from '@veneer/core/dist/esm/scripts/card'
import tokens from '@veneer/tokens'
import styled, { css } from 'styled-components'

const { white, gray9 } = tokens.color

const { size6, cornerRadius2, size2, size1 } = tokens.layout

export interface StyledCardProps {
  $autoHeight?: 'true' | 'false'
  $full?: 'true' | 'false'
}

export const StyledCard = styled(Card)<StyledCardProps>`
  padding: ${size6};
  background-color: ${white} !important;
  height: 100%;
  border-radius: ${cornerRadius2} !important;
  min-height: ${({ $autoHeight }) =>
    $autoHeight === 'true' ? 'auto' : '178px'};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  && {
    color: ${gray9};
  }

  ${({ $full }) =>
    $full === 'true'
      ? css`
          width: 100%;
        `
      : null}
`

export const Header = styled.header`
  margin-bottom: ${size2};
`

export interface ContainerProps {
  $contentPosition: 'bottom' | 'top'
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > section {
    display: flex;
    flex-direction: column;
    height: 100%;

    ${({ $contentPosition }) =>
      $contentPosition === 'bottom'
        ? css`
            justify-content: space-between;
          `
        : css`
            > article {
              height: 100%;
            }
          `}
  }
`

export const Aside = styled.aside`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Footer = styled.footer`
  margin-top: ${size2};
  padding-top: ${size2};
  border-top: 1px solid #dbdbdb;
`

export const IconBlock = styled.div`
  margin-bottom: ${size1};

  & > svg {
    min-height: 32px;
    width: auto;
  }
`
