import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { gray9, hpBlue7 } = tokens.color
const { size1: fontSize1, lineHeight2 } = tokens.typography
const { size1, size3, size2 } = tokens.layout
import { customColors } from '../../../constants/styles'
export const TitleContainer = styled.section`
  margin-bottom: ${size1};
`

export const SupportLabel = styled.p`
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  color: ${gray9};
`

export const ContentLabel = styled(SupportLabel)`
  margin: ${size1} 0 ${size3};
`

export const FooterCardLabel = styled(SupportLabel)`
  color: ${customColors.colorDeepBlue};
  width: 100%;
  text-align: left;
`

export const SupportPhoneNumber = styled.p`
  margin-top: ${size2};
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  text-align: left;
`

export const StyledLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
  &:focus {
    border: 1px solid ${hpBlue7};
    outline: none;
  }
`
