//import { SubscriptionStateEnum } from '@/types'
import DelinquencyNotification from './DelinquencyNotification/DelinquencyNotification'
import { NavigationType } from '@/types/shell'
const GetAllDeliquencyNotification = (
  mfe: string,
  activePrinterData: any,
  navigateToBillingHandler?: () => void,
  navigation?: NavigationType,
  allNotificationsObject?: any
) => {
  switch (mfe) {
    case 'overview':
    case 'updatePlan':
    case 'Trackshipments':
    case 'Subscriptions':
    case 'PrintHistory':
      return DelinquencyNotification(
        mfe,
        activePrinterData,
        null,
        navigation,
        allNotificationsObject
      )

    case 'ShippingBilling':
      return DelinquencyNotification(
        mfe,
        activePrinterData,
        navigateToBillingHandler,
        navigation,
        allNotificationsObject
      )
    default:
      return null
  }
}

export default GetAllDeliquencyNotification
