import { tokens } from '@veneer/tokens'
import styled, { css } from 'styled-components'
import { DescriptionList } from '../../ui'

const { gray9 } = tokens.color
const {
  lineHeight2,
  lineHeight1,
  size1: fontSize1,
  size0: fontSize0
} = tokens.typography
const { size1 } = tokens.layout

const defaultStyling = css`
  letter-spacing: 0px;
`

export const StyledContactName = styled(DescriptionList)`
  color: ${gray9};
  font-size: ${fontSize1};
  line-height: ${lineHeight2};
  margin-bottom: ${size1};
  ${defaultStyling}
  @media(min-width:1280px) {
    font-size: ${fontSize1};
    line-height: ${lineHeight2};
  }
`

export const StyledDescriptionList = styled(DescriptionList)`
  ${defaultStyling}
  color: ${gray9};
  margin-top: 0px;
  margin-bottom: ${size1};
  font-size: ${fontSize0};
  line-height: ${lineHeight1};

  &:last-child {
    margin-top: ${size1};
    margin-bottom: 0px;
  }
`
