/* istanbul ignore file */
import { AxiosError } from 'axios'
import { useState } from 'react'

export interface LazyFetchState<T> {
  called: boolean
  loading: boolean
  error: AxiosError | null
  data: T | null
}

type RequestCallback<T> = () => Promise<T>

export function useLazyFetchRequest<T>(
  request: RequestCallback<T>
): [() => void, LazyFetchState<T>] {
  const [state, setState] = useState<LazyFetchState<T>>({
    data: null,
    error: null,
    loading: false,
    called: false
  })

  const requestThen = (response: T) => {
    setState((previous) => ({
      ...previous,
      data: response,
      loading: false
    }))
  }

  const requestCatch = (error: AxiosError) => {
    setState((previous) => ({
      ...previous,
      error,
      loading: false
    }))
  }

  const fetch = () => {
    if (state.called) return

    setState((previous) => ({
      ...previous,
      called: true,
      loading: true
    }))

    request().then(requestThen).catch(requestCatch)
  }

  return [fetch, state]
}
