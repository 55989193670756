import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Stack } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'

const baseUrl = {
  [Stack.prod]: 'https://us1.api.ws-hp.com/magento/v1/graphql',
  [Stack.stage]: 'https://stage-us1.api.ws-hp.com/magento/v1/graphql',
  [Stack.pie]: 'https://pie-us1.api.ws-hp.com/magento/v1/graphql',
  [Stack.dev]: 'https://pie-us1.api.ws-hp.com/magento/v1/graphql'
}

const useApolloClient = (authProvider: JarvisAuthProvider, stack: Stack) => {
  const authLink = setContext(async (_, { headers }) => {
    const auth = authProvider ? authProvider : window?.Shell?.v1?.authProvider
    const token = await auth?.getAccessToken()

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })
  const client = createHttpLink({
    uri: baseUrl[stack]
  })
  const settings = {
    link: from([authLink, client]),
    cache: new InMemoryCache()
  }

  return new ApolloClient(settings)
}

export default useApolloClient
