import { useSubscriptionDetails } from '@/context/SubscriptionDetailsContext'
import { convertAddOns, convertDevice } from '@/helpers/uiConvert'
import useGetText from '@/hooks/useGetText'
import useSettings from '@/hooks/useSettings'
import { SubscriptionIncludesPanel } from '@/components/shared/SubscriptionIncludesPanel'
import { SubscriptionOptionsResponse } from '@monetization/hpaip-notification-rules-react'
import React from 'react'

export type SubscriptionWrapperProps = {
  isDisabled?: boolean
  inkPlan?: SubscriptionOptionsResponse
  subscriptionId?: string
  isRemovePaperEnabledFlag?: boolean
  activePaperSubscription?: boolean
  shippingAddress?: any
}

export function SubscriptionWrapper(props: SubscriptionWrapperProps) {
  const { assetsProvider } = useSettings()
  const language = assetsProvider.getLanguage()
  const country = assetsProvider.getCountry()
  const locale = `${language}-${country}`
  const getText = useGetText('SubscriptionManagement')
  const { device, addOns } = useSubscriptionDetails()
  return (
    <SubscriptionIncludesPanel
      device={convertDevice(device, locale)}
      addOns={convertAddOns(addOns, locale)}
      t={getText}
      belowTitleArea={null}
      isDisabled={props.isDisabled}
      inkPlan={props?.inkPlan}
      subscriptionId={props.subscriptionId}
      isRemovePaperEnabledFlag={props.isRemovePaperEnabledFlag}
      activePaperSubscription={props.activePaperSubscription}
      shippingAddress={props.shippingAddress}
    />
  )
}
