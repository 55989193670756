import {
  SubscriptionEntity,
  SubscriptionEntityTypeEnum,
  SubscriptionStateEnum
} from '@/types'

export const getActivePrinter = async (
  entities: SubscriptionEntity[]
): Promise<SubscriptionEntity | any> => {
  const allPrinters = entities.filter(
    (entity) => entity.entityType === SubscriptionEntityTypeEnum.PRINTER
  )

  if (allPrinters.length === 1) {
    return { printer: allPrinters[0], replacement: false }
  } else if (allPrinters.length === 0) {
    return { printer: null, replacement: false }
  }

  const activePrinters = allPrinters.filter(
    (entity) => entity.state === SubscriptionStateEnum.SUBSCRIBED
  )

  if (activePrinters.length > 0) {
    return { printer: activePrinters[0], replacement: true }
  }

  const replacedPrinters = allPrinters.filter(
    (entity) =>
      entity.linkedEntities &&
      entity.linkedEntities.some(
        (linkedEntity) => linkedEntity.type === 'replacedBy'
      ) &&
      !entity.linkedEntities.some(
        (linkedEntity) => linkedEntity.type === 'replacedWith'
      )
  )

  if (replacedPrinters.length > 0) {
    return { printer: replacedPrinters[0], replacement: true }
  }

  const pendingReturnPrinters = allPrinters
    .filter((printer) => printer.state === SubscriptionStateEnum.CANCELED)
    .sort(
      (a, b) =>
        new Date(b.entityStartDate).getTime() -
        new Date(a.entityStartDate).getTime()
    )

  if (pendingReturnPrinters[0]) {
    return { printer: pendingReturnPrinters[0], replacement: true }
  }

  return { printer: null, replacement: false }
}
