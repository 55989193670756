export interface InkOffer {
  enable: boolean
  sku: string
  price: number
  pages: number
  rollover: number
  overage: {
    size: number
    price: number
  }
  recommended: boolean
  optionId: string | null
}

export type InkOffersRecord = Record<string, InkOffer>

export interface BillingDetails {
  currency: string
  customerId: string
  orderId: string
  ipAddress: string
  customField: null
  merchantName: string
  paymentMethods: []
  paymentType: string
  paymentState: string
  credentialState: null
  recommendedAction: string
  newPaymentSwitcherStrings: true
  pgsIframeUrl: string
  creditCard: {
    cardTypeKey: string
    cardNumber: string
    displayExpirationDate: string
    cardExpirationDate: string
    expirationStatus: string
    iframeUrl: string
  }
  payPal: {
    env: string
    locale: string
    pay_pal_url: string
  }
}

export enum InstantInkPaymentNotification {
  EXPIRED = 'expired',
  ABOUT_TO_EXPIRED = 'about_to_expire',
  NONE = 'none'
}
