import {
  StratusClient,
  Stack
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { EstimatedCharge } from 'src/types/EstimatedchargesType'
import { CommerceBillingCycle } from 'src/types/billingCyclePeriods'
import { CommerceInvoiceData } from 'src/types/invoice'
import { Customer } from 'src/types/customers'
import { Statement, StatementDownloadLink } from 'src/types/Statement'
import { getAPIURLS } from '../../helpers/getUrlConfig'
import { getStack } from '../../helpers/getStack'
import { CommerceClientResponse } from '@/types/pendingChanges'
import { AxiosResponse } from 'axios'
interface DownloadLink {
  data: string
  message: string
  filename: string
  link: string
}

enum EndpointConstants {
  REFUND = 'refund',
  INVOICES = 'invoices',
  BEARER = 'Bearer '
}
export class CommerceBillingCycleClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(getAPIURLS(getStack(stack)).COMMERCE_BASE_URL, authToken)
  }

  async getBillingDetails(commerceId): Promise<CommerceBillingCycle> {
    return this.jarvisWebHttpInstance
      .get({
        url: `/subscriptions/${commerceId}/details`
      })
      .then(({ data: billingDate }) => billingDate)
  }
  async getSubscriptionData(subscriptionId): Promise<CommerceBillingCycle> {
    try {
      const response = await this.jarvisWebHttpInstance.get({
        url: `/subscriptions/${subscriptionId}`
      })
      return response.data
    } catch (error) {
      throw new Error(`Subscription API failed ${error}`)
    }
  }

  async getEstimatedchargeStatus(customerId): Promise<EstimatedCharge> {
    return this.jarvisWebHttpInstance
      .get({
        url: `/payments/${customerId}/upcoming?includeTaxes=true`
      })
      .then((response) => response.data)
  }

  async getOverdueCharges(customerId): Promise<EstimatedCharge> {
    return this.jarvisWebHttpInstance
      .get({
        url: `/payments/overdue?customerId=${customerId}`
      })
      .then((response) => response.data)
  }

  async getPreviousHistory(
    customerId,
    fromdate,
    todate
  ): Promise<CommerceBillingCycle> {
    return this.jarvisWebHttpInstance
      .get({
        url: `/payments/${customerId}/history?fromdate=${fromdate}&todate=${todate}`
      })
      .then((response) => response.data)
  }
  async getInvoiceData(customerID): Promise<CommerceInvoiceData> {
    return this.jarvisWebHttpInstance
      .get({
        url: `/customers/${customerID}/invoice-data`
      })
      .then((response) => response.data)
  }
  async getCustomerDetailsById(
    tenantId: string
  ): Promise<Customer | undefined> {
    if (!tenantId) return undefined
    return this.jarvisWebHttpInstance
      .get({ url: `/customers?userTenantId=${tenantId}` })
      .then((response) => response.data)
  }

  constructDownloadURL = (statement: Statement) => {
    const purchaseTypes = [
      'Purchase',
      'Purchase_Cancellation',
      'purchase_cancellation_refund',
      'purchase_refund'
    ]

    const base = `${
      purchaseTypes.includes(statement?.resourceType)
        ? 'v2/purchases'
        : 'orders'
    }`

    return statement.resourceType.includes(EndpointConstants.REFUND)
      ? `${base}/${statement.parentResourceId}/${EndpointConstants.INVOICES}?TYPE=REFUNDS`
      : `${base}/${statement.resourceId}/${EndpointConstants.INVOICES}`
  }

  async getStatementDownload(statement: Statement) {
    const downloadURL = this.constructDownloadURL(statement)
    return this.jarvisWebHttpInstance
      .get({ url: `${downloadURL}` })
      .then(({ data }) => {
        return statement.resourceType.includes(EndpointConstants.REFUND)
          ? (data?.invoiceList?.find(
              (item: StatementDownloadLink) =>
                item.refundResourceId === statement.resourceId
            ) as DownloadLink)
          : (data?.invoiceList?.[0] as DownloadLink)
      })
  }

  getCommerceDetails(
    Id: string
  ): Promise<AxiosResponse<CommerceClientResponse>> {
    return this.jarvisWebHttpInstance.get({
      url: `/subscriptions/${Id}/details`
    })
  }
}
