import React, { useState } from 'react'
import { ThemeProvider as StyleThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import { TranslatorFunctionType } from '@/types/localization'
import IconPrinterInkDrop from '@veneer/core/dist/esm/scripts/icons/icon_printer_ink_drop'
import { AddOn } from '../types/subscription'

import {
  BoosterCardPanel,
  LabelDarkBlock,
  PrintPlanBlock,
  FooterWrapper,
  ChevronRightIcon
} from './styles'

import { SubscriptionOptionsResponse } from '@monetization/hpaip-notification-rules-react'
import { InkPlanDetails } from '@monetization/hpaip-ui-shared-components'
import {
  AddOnPaperStepOne,
  PaperOnPaasModal
} from '@monetization/hpaip-ui-shared-components'
import { useFlags } from 'launchdarkly-react-client-sdk'

type AddonProps = {
  t: TranslatorFunctionType
  isDisabled?: boolean
  state?: string
  inkPlan?: SubscriptionOptionsResponse
  subscriptionId?: string
  isRemovePaperEnabledFlag?: boolean
  activePaperSubscription?: boolean
  shippingAddress?: any
} & AddOn

export function AddOnDevice(props: AddonProps) {
  const {
    t,
    isDisabled,
    state,
    inkPlan,
    subscriptionId,
    isRemovePaperEnabledFlag,
    activePaperSubscription,
    shippingAddress
  } = props

  const { enableAddPaperPostEnrollment, enableManagePaperPostEnrollment } =
    useFlags()
  const hasUcdeInPath = window?.location?.pathname?.includes('/ucde') || false
  const removeUCDE = hasUcdeInPath ? '/ucde' : ''
  const isRemovePaperEnabled =
    isRemovePaperEnabledFlag && enableManagePaperPostEnrollment
  const isCancellationInProgress =
    state === 'deactivating' || state === 'inactive' || state === 'pending'

  const handleRemovePaperClick = () => {
    const paperPlanCancellationUrl = `${removeUCDE}/hp-all-in-print-plan/cancellation/${subscriptionId}?entity=paper`
    window.location.href = paperPlanCancellationUrl
  }
  const handleChangePlan = () => {
    const paperPlanChangeUrl = `${removeUCDE}/hp-all-in-print-plan/update-plan`
    window.location.href = paperPlanChangeUrl
  }
  const handleAddPaperClick = () => {
    setModalState((prevState) => ({
      ...prevState,
      showPaperOnPaas: !prevState.showPaperOnPaas
    }))
  }
  const [modalState, setModalState] = useState({
    stepOne: false,
    stepTwo: false,
    error: false,
    showPaperOnPaas: false
  })
  const handlePaperStepOneSubmit = async () => {
    setModalState({ ...modalState, stepOne: true })
  }

  const printPlanAddon = (
    <StyleThemeProvider theme={useTheme()}>
      <BoosterCardPanel>
        <PrintPlanBlock isDisabled={isDisabled}>
          <IconPrinterInkDrop size={32} className="iconLabel" />
          <span className="body-large">
            {t('master-device-detail.print-plan', {
              defaultValue: 'My plan'
            })}
          </span>
        </PrintPlanBlock>
        <div>
          <LabelDarkBlock isDisabled={isDisabled}>
            <InkPlanDetails
              t={t}
              pages={inkPlan?.pagesPerMonth}
              rollover={inkPlan?.rolloverPages}
              additional={inkPlan?.additionalPages}
              additionalPrice={inkPlan?.additionalPagesPrice}
              handleaddpaperService={handleAddPaperClick}
              handleremovePaperService={handleRemovePaperClick}
              paperEligibility={
                isRemovePaperEnabled
                  ? 'removePaperService'
                  : enableAddPaperPostEnrollment
                  ? 'addPaperService'
                  : ''
              }
              isSubscriptionHavePaper={activePaperSubscription}
            />
          </LabelDarkBlock>
        </div>

        <FooterWrapper disabled={isCancellationInProgress}>
          <span onClick={handleChangePlan}>
            {t('ink-plan-details.change-plan', {
              defaultValue: 'Change Plan'
            })}
          </span>
          <ChevronRightIcon onClick={handleChangePlan} />
        </FooterWrapper>

        {modalState.showPaperOnPaas && (
          <>
            <AddOnPaperStepOne
              show={modalState.showPaperOnPaas}
              pricePlan="50"
              pagesPerMonth="100"
              onClose={() => setModalState({ ...modalState, stepOne: true })}
              onSubmit={handlePaperStepOneSubmit}
            />
            <PaperOnPaasModal
              onClose={() => {
                setModalState({ ...modalState, stepOne: false })
              }}
              show={modalState.stepOne}
              pricePlan="50"
              pagesPerMonth="100"
              shippingAddress={{
                fullName: `${shippingAddress?.firstName} ${shippingAddress?.lastName}`,
                address: `${shippingAddress?.address}`,
                address2: `${shippingAddress?.address2}`,
                city: `${shippingAddress?.city}`,
                state: `${shippingAddress?.state}`,
                postalCode: `${shippingAddress?.postalCode}`,
                countryCode: `${shippingAddress?.countryCode}`
              }}
              handleChange={handleChangePlan}
            />
          </>
        )}
      </BoosterCardPanel>
    </StyleThemeProvider>
  )
  return printPlanAddon
}
