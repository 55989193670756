import React from 'react'
import { DefaultExportProps } from '../../../types/defaultExportProps'
import { ContentLabel, SupportLabel } from './styles'

export const HPOneSupport: React.FunctionComponent<DefaultExportProps> = ({
  t
}) => {
  return (
    <>
      <SupportLabel>
        {t(
          'hp-one-support.support-avaliable',
          'If you need help with HP All-In plans or devices, contact your dedicated live support team, anytime day or night.'
        )}
      </SupportLabel>
      <ContentLabel>{t('hp-one-support.contact', '')}</ContentLabel>
    </>
  )
}
