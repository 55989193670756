export enum NotificationType {
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
  WARNING = 'warning',
  INFORMATIVE = 'informative'
}
export enum NotificationID {
  PRESHIPPED_AFTERCANCEL = 'preshipped_aftercancel',
  PRESHIPPED_BEFORECANCEL = 'preshipped_beforecancel',
  OVERVIEW_PREARRIVAL = 'overview-prearrival',
  UPDATEPLAN_PREARRIVAL = 'updatePlan-prearrival',
  TRACKSHIPMENTS_PREARRIVAL = 'trackshipments-prearrival',
  SUBSCRIPTION_PREARRIVAL = 'subscription-prearrival',
  OVERVIEW_OFFLINE = 'overview-offline',
  SHIPMENTTRACKING_OFFLINE = 'shipmentTracking-offline',
  CANCELING_NEW = 'CANCELING-new',
  CANCELING_INITIATED = 'canceling-initiated',
  CANCELING_PROCESSING = 'canceling-processing',
  CANCELING_PROCESSING_AFTER_REMORSE = 'canceling-processing-after-remorse',
  CANCELING_ITEMS_RECEIVED_IN_REMORSE = 'canceling-items-received-in-remorse',
  CANCELING_ITEMS_RECEIVED_AFTER_REMORSE = 'canceling-items-received-after-remorse',
  CANCELING_LAST_DAY_TO_RETURN = 'canceling-last-Day-To-Return',
  CANCELING_RETURN_TODAY = 'canceling-return-today',
  CANCELED_COMPLETE_SUCCESSFUL_IN_REMORSE = 'canceled-complete-successful-in-remorse',
  CANCELED_COMPLETE_SUCCESSFUL_AFTER_REMORSE = 'canceled-complete-successful-after-remorse',
  CANCELED_COMPLETE_TIMEOUT_IN_REMORSE = 'canceled-complete-timeout-in-remorse',
  CANCELED_COMPLETE_TIMEOUT_AFTER_REMORSE = 'canceled-complete-timeout-after-remorse',
  CANCELED_COMPLETE_UNSUCCESSFUL = 'canceled-complete-unsuccessful',

  CANCELED_TIMEOUT_IN_REMORSE = 'canceled-timeout-in-remorse',
  CANCELED_TIMEOUT_AFTER_REMORSE = 'canceled-timeout-after-remorse',
  CANCELED_CANCELLED = 'canceled-cancelled',
  ABOUT_TO_EXPIRED = 'about-to-expire',
  EXPIRED = 'expired'
}
