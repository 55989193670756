import styled from 'styled-components'
import tokens from '@veneer/tokens'
import { CardTitle } from '../../ui'

const { size5, size4, size8 } = tokens.layout
const {
  family0,
  size5: fontSize5,
  lineHeight5,
  size7,
  lineHeight7
} = tokens.typography
const { gray12 } = tokens.color
export const Header = styled.header`
  box-sizing: border-box;
  background-color: transparent;
  padding-top: ${size5};
  padding-right: ${size4};
  padding-left: ${size4};
  padding-bottom: 40px;
  @media (min-width: 500px) {
    padding-top: ${size5};
    padding-right: ${size5};
    padding-left: ${size5};
    padding-bottom: 40px;
  }
  @media (min-width: 641px) {
    padding-top: ${size8};
    padding-right: ${size8};
    padding-left: ${size8};
    padding-bottom: 40px;
  }
  @media (min-width: 1200px) {
    padding: 40px;
  }
`

export const Title = styled(CardTitle)`
  font-family: ${family0};
  font-style: normal;
  font-size: ${fontSize5};
  line-height: ${lineHeight5};
  color: ${gray12};
  @media (min-width: 641px) {
    font-size: ${size7};
    line-height: ${lineHeight7};
  }
  @media (min-width: 1200px) {
    font-size: ${size7};
    line-height: ${lineHeight7};
  }
`
