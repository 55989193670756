import { SubscriptionEntity } from '@/types'

const isDeviceShippedOrDelivered = (
  entityFulfilment: any,
  entity: SubscriptionEntity
) => {
  let foundPart

  const enrollmentOrder = entityFulfilment.filter(
    (item) => item.productType === 'hardware'
  )
  const possible = enrollmentOrder[0].parts.find(
    (part) => part.modelSku === entity.product.value.productSku
  )
  if (possible) foundPart = possible

  if (!foundPart) return false
  const statuses = foundPart.history?.map((old) => old.status) || []
  statuses.push(foundPart.status)
  return Boolean(statuses.includes('shipped'))
}

export default isDeviceShippedOrDelivered
