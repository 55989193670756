import environments from '../../env.json'

const setEnvironmentVariables = (stack) => {
  const env = environments.find((e) => e.stack === stack)
  if (env) {
    return env
  }
  return undefined
}

// Export the URLS constant with the environment variables
export const getAPIURLS = (stack) =>
  setEnvironmentVariables(stack).variables.API_URL

export const getVariables = (stack) => setEnvironmentVariables(stack).variables
