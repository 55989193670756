import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useContext
} from 'react'
import {
  AnalyticsEvents,
  CdmEventDefaults,
  CdmEvent,
  DataValveCDMEvent,
  PublishCdmEventsOptions,
  ShellAnalytics
} from './AnalyticsContext.types'

export type EnchancedCdmEvent = CdmEvent | CdmEvent[]

type Analytics = {
  publishEvent: (
    event: EnchancedCdmEvent,
    options?: PublishCdmEventsOptions
  ) => void
  publishEventPromise: (
    event: EnchancedCdmEvent,
    options?: PublishCdmEventsOptions
  ) => Promise<void>
  events: AnalyticsEvents
}

export const AnalyticsContext = createContext<Analytics>(undefined)

interface AnalyticsProviderProps {
  analytics: ShellAnalytics
  defaults: CdmEventDefaults
  events: AnalyticsEvents
}

export const AnalyticsProvider: FunctionComponent<AnalyticsProviderProps> = ({
  analytics,
  defaults,
  events,
  children
}) => {
  const publishEvent = useCallback(
    (event: EnchancedCdmEvent, options?: PublishCdmEventsOptions) => {
      const publishCdmEvents = analytics?.publishCdmEvents

      if (!publishCdmEvents) {
        console.error('shell.analytics.publishCdmEvents is not defined')
        return
      }

      const eventList = Array.isArray(event) ? event : [event]

      const dateTime = new Date().toISOString()

      const cdmEvents = eventList.map<DataValveCDMEvent>((eventItem) => {
        return {
          dateTime,
          eventDetailType:
            'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
          eventCategory: 'simpleUi',
          eventDetail: {
            ...defaults,
            ...eventItem
          } as CdmEvent,
          version: '1.4.0'
        }
      })

      setTimeout(() => publishCdmEvents(cdmEvents, options), 1000)
    },
    [analytics, defaults]
  )

  const publishEventPromise = useCallback(
    (event: EnchancedCdmEvent, options?: PublishCdmEventsOptions) => {
      return new Promise<void>((resolve) => {
        resolve(publishEvent(event, options))
      })
    },
    [publishEvent]
  )

  return (
    <AnalyticsContext.Provider
      value={{ publishEvent, publishEventPromise, events }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext)

  return context
}

export const parseAuxParams = (params: Record<string, string>) => {
  return {
    actionAuxParams: Object.entries(params).reduce(
      (acc, [key, value], index) => {
        if (index > 0) acc = `${acc}&`
        acc += `${key}=${value}`
        return acc
      },
      ''
    )
  }
}
