import {
  StratusClient,
  Stack
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { getAPIURLS } from '../../helpers/getUrlConfig'
import { getStack } from '../../helpers/getStack'

export class DeviceClient extends StratusClient {
  stack: Stack
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(getAPIURLS(getStack(stack)).DEVICE_CACHE_URL, authToken)
    this.stack = stack
  }
  //`/orders/tenant/${tenantId}`,
  async getDeviceCacheInfo(uniqueId: string): Promise<string> {
    return this.jarvisWebHttpInstance
      .get({
        url: `/${uniqueId}`
      })
      .then(({ data }) => {
        return data
      })
  }
}
