import { useState, useEffect } from 'react'
import { InstantInkClient } from '@/services/InstantInkClient'

export const useInstantInk = (authProvider, stack) => {
  const [billingData, setBillingData] = useState(null)

  useEffect(() => {
    ;(async () => {
      const instantInkClientData = new InstantInkClient(authProvider, stack)
      try {
        const { data } = await instantInkClientData.getInstantInkClientDetails()

        setBillingData(data)
      } catch (err) {
        setBillingData('404')
      }
    })()
  }, [authProvider, stack])

  return billingData
}
