import * as React from 'react'
import { ChildRow, ContainerRow } from './styles'

type Alignment = 'between' | 'around' | 'left' | 'right' | 'center'

const HORIZONTAL_ALIGNMENT: Record<Alignment, string> = {
  between: 'space-between',
  around: 'space-around',
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
}

interface RowProps {
  preventHorizontalStretch?: boolean
  preserveLayoutOnMobile?: boolean
  columnSize?: number
  horizontalAlign?: Alignment
}

export const Row: React.FunctionComponent<RowProps> = ({
  preventHorizontalStretch,
  columnSize,
  preserveLayoutOnMobile,
  horizontalAlign,
  children
}) => {
  if (preventHorizontalStretch && !columnSize) {
    console.warn(
      '<Row /> :: When using preventHorizontalStretch is required to use columnSize prop to calculate with accuracy the child width'
    )
  }

  return (
    <ContainerRow
      $preserveLayoutOnMobile={preserveLayoutOnMobile}
      $horizontalAlign={HORIZONTAL_ALIGNMENT[horizontalAlign]}
      data-testid="row"
    >
      {React.Children.map(children, (child) => (
        <ChildRow
          $preventHorizontalStretch={preventHorizontalStretch}
          $columnSize={columnSize}
          $preserveLayoutOnMobile={preserveLayoutOnMobile}
          data-testid="row-child"
        >
          {child}
        </ChildRow>
      ))}
    </ContainerRow>
  )
}
