import React, { FC } from 'react'
import {
  StyledModal,
  ImageBlock,
  ModalBody,
  Title,
  SubTitle,
  PriceText,
  PagesText,
  ShippingText,
  AddressText,
  ChangeText,
  ButtonBlock
} from './styles'
import useGetText from '../../hooks/useGetText'
import Images from '../../../assets/images'
import { getDefaultText, getPagesText, getPriceText } from './defaultText'
import Button from '@veneer/core/dist/scripts/button'

interface ShippingAddress {
  fullName: string
  address: string
  address2?: string
  city: string
  state: string
  postalCode: string
  countryCode: string
}

interface PaperOnPaasModalProps {
  show: boolean
  pricePlan: string
  pagesPerMonth: string
  shippingAddress: ShippingAddress
  onClose: () => void
  handleChange: () => void
}

export const PaperOnPaasModal: FC<PaperOnPaasModalProps> = (
  props: PaperOnPaasModalProps
) => {
  const {
    show,
    pricePlan,
    pagesPerMonth,
    shippingAddress,
    onClose,
    handleChange
  } = props
  const getText = useGetText('paper-on-paas')

  return (
    <StyledModal show={show} closeButton onClose={onClose}>
      <ImageBlock>
        <img src={Images.AOPModalBanner} alt="banner" />
      </ImageBlock>
      <ModalBody>
        <Title>
          {getText('title', { defaultText: getDefaultText('title') })}
        </Title>
        <SubTitle data-testid="sub-title">
          {getText('sub-title', {
            defaultText: getDefaultText('sub-title')
          })}
        </SubTitle>
        <PriceText>
          {getText('price-text', {
            pricePlan,
            defaultText: getPriceText(pricePlan)
          })}
        </PriceText>
        <PagesText>
          {getText('pages-text', {
            pagesPerMonth,
            defaultText: getPagesText(pagesPerMonth)
          })}
        </PagesText>
        {shippingAddress && (
          <>
            <ShippingText data-testid="shipping-text">
              {getText('shipping-text', {
                default: getDefaultText('shipping-text')
              })}
            </ShippingText>
            <AddressText>{shippingAddress?.fullName}</AddressText>
            <AddressText>{shippingAddress?.address}</AddressText>
            {shippingAddress?.address2 && (
              <AddressText>{shippingAddress?.address2}</AddressText>
            )}
            <AddressText>
              {`${shippingAddress?.city}, ${shippingAddress?.state} ${shippingAddress?.postalCode} ${shippingAddress?.countryCode}`}
            </AddressText>
            <ChangeText onClick={handleChange}>
              {getText('change', {
                defaultText: getDefaultText('change')
              })}
            </ChangeText>
          </>
        )}
        <ButtonBlock>
          <Button onClick={onClose}>
            {getText('done', { defaultText: getDefaultText('done') })}
          </Button>
        </ButtonBlock>
      </ModalBody>
    </StyledModal>
  )
}
