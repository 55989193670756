import IconInfo from '@veneer/core/dist/esm/scripts/icons/icon_info'
import Tooltip from '@veneer/core/dist/esm/scripts/tooltip'
import * as React from 'react'
import { DefaultExportProps } from '../../../types/defaultExportProps'
import { LinkPaper, SubText, TextBox } from './styles'
import { DescriptionList } from '../../ui/DescriptionList/DescriptionList'
import { CdmEvent, useAnalytics } from '../../contexts/AnalyticsContext'

interface IInkPlanDetailsProps extends DefaultExportProps {
  pages: number
  rollover: number
  additional: number
  additionalPrice: number
  isSubscriptionHavePaper?: boolean
  rollOverPagesEvent?: CdmEvent
  additionalPagesEvent?: CdmEvent
  paperEligibility: string
  handleaddpaperService: () => void
  handleremovePaperService: () => void
}

export const InkPlanDetails: React.FunctionComponent<IInkPlanDetailsProps> = ({
  pages,
  rollover,
  additional,
  additionalPrice,
  isSubscriptionHavePaper = false,
  t,
  paperEligibility,
  handleaddpaperService,
  handleremovePaperService,
  additionalPagesEvent,
  rollOverPagesEvent
}) => {
  const analytics = useAnalytics()
  const additionalPagesPrice = additionalPrice.toFixed(2)
  // Render based on the value of paperEligibility
  const renderContentBasedOnPaperEligibility = () => {
    switch (paperEligibility) {
      case 'addPaperService':
        return (
          <DescriptionList
            description={
              <TextBox>
                <LinkPaper
                  data-testid="add_paper_link"
                  onClick={handleaddpaperService}
                >
                  {t('ink-plan-details.add-paper-plan', {
                    defaultValue: 'Add paper to your plan'
                  })}
                </LinkPaper>
              </TextBox>
            }
          />
        )
      case 'removePaperService':
        return (
          <DescriptionList
            description={
              <TextBox>
                <span>
                  {t('ink-plan-details.paper-service', {
                    defaultValue: 'Paper Add-on Service included.'
                  })}
                </span>
                <LinkPaper
                  data-testid="remove_paper_link"
                  onClick={handleremovePaperService}
                >
                  {t('ink-plan-details.add-paper-pldan', {
                    defaultValue: 'Remove paper'
                  })}
                </LinkPaper>
              </TextBox>
            }
          />
        )
      default:
        return null // Default case to handle unexpected values
    }
  }

  return (
    <>
      {isSubscriptionHavePaper && (
        <SubText>
          {t(
            'dashboard.paas.ink-plan-details.instant-ink-paper-info',
            'We ship you ink and paper automatically based on usage, not a monthly schedule.'
          )}
        </SubText>
      )}
      <div>
        <DescriptionList
          description={
            <TextBox>
              <span>
                {t('ink-plan-details.pages-per-month', {
                  pages,
                  defaultValue: '{{pages}} pages/month'
                })}
              </span>
            </TextBox>
          }
        />
        {renderContentBasedOnPaperEligibility()}

        <DescriptionList
          description={
            <TextBox>
              <span>
                {t('ink-plan-details.rollover-pages', {
                  rollover,
                  defaultValue: 'Rollover up to {{rollover}} pages'
                })}
              </span>
              <Tooltip
                content={t(
                  'ink-plan-details.rollover-pages-tooltip',
                  'Unused pages roll over to your next monthly allotment'
                )}
                placement="top"
              >
                <IconInfo
                  size={16}
                  onMouseEnter={() =>
                    analytics?.publishEvent(rollOverPagesEvent)
                  }
                  data-testid="tooltip-rollover-pages"
                />
              </Tooltip>
            </TextBox>
          }
        />
        <DescriptionList
          description={
            <TextBox>
              <span>
                {t('ink-plan-details.additional-pages', {
                  additional,
                  additionalPagesPrice,
                  defaultValue:
                    'Additional {{additional}} pages for ${{additionalPagesPrice}}'
                })}
              </span>
              <Tooltip
                content={t(
                  'ink-plan-details.additional-pages-tooltip',
                  'You can add page sets to your account if you need to print more than your plan and rollover pages allow'
                )}
                placement="top"
              >
                <IconInfo
                  size={16}
                  onMouseEnter={() =>
                    analytics?.publishEvent(additionalPagesEvent)
                  }
                  data-testid="tooltip-additional-pages"
                />
              </Tooltip>
            </TextBox>
          }
        />
      </div>
    </>
  )
}
