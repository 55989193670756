import { JarvisAuthProvider } from '@jarvis/web-http'
import {
  Stack,
  StratusClient
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { AxiosResponse } from 'axios'
import {
  SubscriptionStateResponse,
  UserSubscriptions
} from 'src/types/subscriptionState'
import { PaginatedResponse } from 'src/types/pendingChanges'
import { getAPIURLS } from '../../helpers/getUrlConfig'
import { getStack } from '../../helpers/getStack'
export class SubscriptionsStateClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(getAPIURLS(getStack(stack)).SUBSCRIPTION_STATE_URL, authToken)
  }

  getAllSubscriptions(): Promise<SubscriptionStateResponse[]> {
    return this.jarvisWebHttpInstance
      .get({ url: `` })
      .then((result: AxiosResponse<UserSubscriptions>) => result.data.contents)
  }

  getSubscriptionById(
    subscriptionId: string
  ): Promise<AxiosResponse<SubscriptionStateResponse>> {
    return this.jarvisWebHttpInstance.get({
      url: `/${subscriptionId}`
    })
  }

  getPendingChanges(
    subscriptionId: string
  ): Promise<AxiosResponse<PaginatedResponse>> {
    return this.jarvisWebHttpInstance.get({
      url: `/${subscriptionId}/pending-changes`
    })
  }
}
