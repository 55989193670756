import React, { FC, useState } from 'react'
import {
  ButtonBlock,
  CheckboxBlock,
  CheckboxText,
  Description,
  Divider,
  FeatureBlock,
  ImageBlock,
  LinkBlock,
  LinkText,
  List,
  ListText,
  ModalBody,
  StyledModal,
  Title
} from './styles'
import Checkbox from '@veneer/core/dist/scripts/checkbox'
import Button from '@veneer/core/dist/scripts/button'
import Images from '../../../assets/images'
import useGetText from '../../hooks/useGetText'
import { TermsAndConditions } from '../TermsAndConditions'
import { FaqModal } from '../FaqModal'
import { getDefaultCheckboxText, getDefaultText } from './defaultText'

interface AddOnPaperProps {
  show: boolean
  pricePlan: string
  pagesPerMonth: string
  onClose: () => void
  onSubmit: () => void
}

export const AddOnPaperStepOne: FC<AddOnPaperProps> = (
  props: AddOnPaperProps
) => {
  const { show, pricePlan, pagesPerMonth, onClose, onSubmit } = props
  const getText = useGetText('addOnPaperStepOne')

  const [addPaperBtn, setAddPaperBtn] = useState(false)
  const [tosModal, setTosModal] = useState(false)
  const [faqModal, setFaqModal] = useState(false)
  const handleAddPaperBtn = () => {
    setAddPaperBtn(!addPaperBtn)
  }

  const handleModalClose = () => {
    setAddPaperBtn(false)
    onClose()
  }

  const handleSubmit = () => {
    setAddPaperBtn(false)
    onSubmit()
  }
  const handleTosModal = () => {
    setTosModal(!tosModal)
  }
  const handleFaqModal = () => {
    setFaqModal(!faqModal)
  }
  return (
    <>
      <StyledModal show={show} closeButton onClose={handleModalClose}>
        <ImageBlock>
          <img src={Images.AOPModalBanner} alt="banner" />
        </ImageBlock>
        <ModalBody>
          <Title>
            {getText('title', {
              defaultText: getDefaultText('title')
            })}
          </Title>
          <Description>
            {getText('description.title', {
              defaultText: getDefaultText('description')
            })}
          </Description>
          <Divider />

          <FeatureBlock>
            <p>&bull;</p>
            <Description>
              {getText('description.featureOne', {
                defaultText: getDefaultText('featureOne')
              })}
            </Description>
          </FeatureBlock>
          <FeatureBlock>
            <p>&bull;</p>
            <Description>
              {getText('description.featureTwo', {
                defaultText: getDefaultText('featureTwo')
              })}
            </Description>
          </FeatureBlock>
          <FeatureBlock>
            <p>&bull;</p>
            <Description>
              {getText('description.featureThree', {
                defaultText: getDefaultText('featureThree')
              })}
            </Description>
          </FeatureBlock>
          <CheckboxBlock>
            <Checkbox checked={addPaperBtn} onChange={handleAddPaperBtn} />
            <CheckboxText>
              {getText('checkboxText', {
                pagesPerMonth,
                pricePlan,
                defaultText: getDefaultCheckboxText(pagesPerMonth, pricePlan)
              })}
            </CheckboxText>
          </CheckboxBlock>
          <LinkBlock>
            <LinkText onClick={handleFaqModal} data-testid="faq">
              {getText('faq', { defaultText: getDefaultText('faq') })}
            </LinkText>
            <LinkText onClick={handleTosModal}>
              {getText('tos', { defaultText: getDefaultText('tos') })}
            </LinkText>
          </LinkBlock>
          <List>
            <ListText>
              {getText('list.text1', {
                defaultText: getDefaultText('listText1')
              })}
            </ListText>
            <ListText>
              {getText('list.text2', {
                defaultText: getDefaultText('listText2')
              })}
            </ListText>
            <ListText>
              {getText('list.text3', {
                defaultText: getDefaultText('listText3')
              })}
            </ListText>
          </List>
          <ButtonBlock>
            <Button
              data-testid="btn1-test"
              appearance="secondary"
              colorScheme="default"
              onClick={handleModalClose}
            >
              {getText('btn1Label', {
                defaultText: getDefaultText('btn1Label')
              })}
            </Button>
            <Button
              appearance="primary"
              data-testid="btn2-test"
              disabled={!addPaperBtn}
              onClick={handleSubmit}
            >
              {getText('btn2Label', {
                defaultText: getDefaultText('btn2Label')
              })}
            </Button>
          </ButtonBlock>
        </ModalBody>
      </StyledModal>
      <TermsAndConditions openModel={tosModal} onClose={handleTosModal} />
      <FaqModal show={faqModal} onClose={handleFaqModal} />
    </>
  )
}
