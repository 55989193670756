const checkCancellationTriggered = (pendingData: any) => {
  if (!pendingData) return false

  const filteredCancellations = pendingData.contents.filter(
    (content: any) =>
      content.type === 'cancellation' &&
      content.reason.type !== 'warrantyRepairReplacement'
  )

  return Boolean(filteredCancellations[0]) || false // Return the first element or false
}

export default checkCancellationTriggered
