import React from 'react'
import { ThemeProvider as StyleThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import { AddOn, Device } from '../../../types/subscription'
import { TranslatorFunctionType } from '../../../types/localization'
import { Panel, AddonsContainer } from './styles'
import { AddOnDevice } from '../AddOnDevice'
import { MasterDeviceDetail } from '../MasterDeviceDetail'

type SubPanelProps = {
  t: TranslatorFunctionType
  addOns?: AddOn[]
  device?: Device
  belowTitleArea?: React.ReactNode
}

export function SubscriptionIncludesPanel(props: SubPanelProps) {
  const { addOns, device, belowTitleArea, t } = props as SubPanelProps
  return (
    <StyleThemeProvider theme={useTheme()}>
      <Panel>
        {device && (
          <MasterDeviceDetail
            key={device.name}
            {...device}
            t={t}
            belowTitleArea={belowTitleArea}
          />
        )}
        {addOns && addOns.length > 0 && (
          <AddonsContainer>
            {addOns.map(
              (val) => val.name && <AddOnDevice key={val.name} {...val} t={t} />
            )}
          </AddonsContainer>
        )}
      </Panel>
    </StyleThemeProvider>
  )
}
