import styled from 'styled-components'
import tokens from '@veneer/tokens'
const { size6 } = tokens.layout

export const Circle = styled.div`
  float: right;

  @media (min-width: 768px) {
    margin-right: ${size6};
  }
`
export const Image = styled.img`
  width: 30px;
  height: 30px;
`
export const InkColor = styled('p')<{ item: string }>`
  color: #ffffff;
  background: ${(props) =>
    props.item === 'K'
      ? '#000000'
      : props.item === 'C'
      ? '#0095D5'
      : props.item === 'M'
      ? '#F7448B'
      : props.item === 'Y'
      ? '#FDD711'
      : null};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  align-items: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
`
