import { IconCalendarDay } from '@veneer/core/dist/esm/scripts/icons'

import React from 'react'
import styled from 'styled-components'
import { DefaultExportProps } from '../../../types/defaultExportProps'
import { DescriptionList } from '../../ui/'
import { Divisor, TextBox } from './styles'

const StyledIconCalendarDay = styled(IconCalendarDay)`
  margin-right: 3px;
`

export interface SubscriptionDetailsProps extends DefaultExportProps {
  subscriptionId: string
  subscriptionStartDate: string
}

export const SubscriptionDetails: React.FunctionComponent<
  SubscriptionDetailsProps
> = ({ t, subscriptionId, subscriptionStartDate }) => {
  return (
    <>
      <Divisor>
        <DescriptionList
          description={t('subscription-details.subscription-id', 'Plan ID')}
          value={subscriptionId}
          direction="column"
        />
      </Divisor>
      <Divisor>
        <DescriptionList
          description={t('subscription-details.start-date', 'Start date')}
          value={
            <TextBox>
              <StyledIconCalendarDay size={20} color="colorGray7" />
              <span>{subscriptionStartDate}</span>
            </TextBox>
          }
          direction="column"
        />
      </Divisor>
    </>
  )
}
