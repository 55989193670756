import styled from 'styled-components'
import tokens from '@veneer/tokens'
import Modal from '@veneer/core/dist/esm/scripts/modal'

const { color, layout, typography } = tokens

export const StyledModal = styled(Modal)`
  && {
    .vn-modal__close > span > svg {
      color: ${color.white};
      background-color: ${color.gray9};
      border-radius: 50%;
      padding: 2px;
    }

    .vn-modal--content {
      padding: ${layout.size0};
      max-width: 750px;
    }
  }

  @media (max-width: ${layout.mdMax}) {
    .vn-modal--content {
      width: 75%;
    }
  }

  @media (max-width: ${layout.smMin}) {
    .vn-modal--content {
      width: 90%;
    }
  }
`

export const ImageBlock = styled.div`
  > img {
    width: 100%;
  }
`

export const ModalBody = styled.div`
  padding: 40px 48px;
  color: ${color.gray12};

  font-family: ${typography.family0};
  @media (max-width: ${layout.smMin}) {
    padding: ${layout.size8} ${layout.size4};
  }
`

export const Title = styled.p`
  font-size: ${typography.size7};
  line-height: ${typography.lineHeight7};
  margin-bottom: ${layout.size6};

  @media (max-width: ${layout.smMin}) {
    font-size: ${typography.size5};
    line-height: ${typography.lineHeight5};
  }
`

export const SubTitle = styled.p`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
  margin-bottom: ${layout.size6};

  @media (max-width: ${layout.smMin}) {
    font-size: ${typography.size1};
    line-height: ${typography.lineHeight2};
  }
`
export const PriceText = styled.p`
  font-size: ${typography.size3};
  line-height: ${typography.lineHeight4};
`

export const PagesText = styled.p`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
  margin-bottom: ${layout.size6};
`

export const ShippingText = styled.p`
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
`

export const AddressText = styled.p`
  font-size: ${typography.size0};
  line-height: ${typography.lineHeight1};
  text-transform: capitalize;
`

export const ChangeText = styled.p`
  color: ${color.hpBlue7};
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
  margin-top: ${layout.size4};
  cursor: pointer;
`

export const ButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${layout.size6};
`
