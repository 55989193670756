import { BundleProduct } from '@/__generated__/graphql'

export const getNumOfDaysLeft = async (
  createdAtFromAPI: Date | string,
  product: BundleProduct
): Promise<number> => {
  let limit = 10
  if (product) {
    limit = product.hp_max_days_to_return
  }

  const addDay = (date: string | number): Date => {
    const returningDate = new Date(date)
    returningDate.setDate(returningDate.getDate() + limit)
    return new Date(returningDate)
  }

  const convertToTime = (date: Date | string): number =>
    new Date(date).getTime()

  const cancellationStartDate = convertToTime(createdAtFromAPI)
  const limitDate = addDay(cancellationStartDate)

  const numberOfDaysLeft = Math.ceil(
    (convertToTime(limitDate) - Date.now()) / (1000 * 3600 * 24)
  )

  return Math.max(0, numberOfDaysLeft)
}
