import styled from 'styled-components'

export const DelinquencyStyledNotification = styled.div`
  padding: 0;
  width: 100%;
  > div {
    border-radius: 0;
    margin: 0;
    padding: 0 40px;
  }
  .custom-link {
    display: inline;
    cursor: pointer;
  }
`
export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
`
