import {
  StratusClient,
  Stack
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { AxiosResponse } from 'axios'
import { UsageData } from 'src/types/usageData'
import { BillingCyclePeriodsResponse } from 'src/types/billingCyclePeriods'
import { getAPIURLS } from '../../helpers/getUrlConfig'
import { getStack } from '../../helpers/getStack'

export class BillingCyclePeriodsClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(getAPIURLS(getStack(stack)).MBLC_BASE_URL, authToken)
  }

  async getBillingCycleUsageByBillingCycleId(
    blId: string,
    subscriptionId: string,
    entityId: string,
    billingCycleId: string
  ): Promise<UsageData> {
    return this.jarvisWebHttpInstance
      .get({
        url: `/${blId}/usage/${subscriptionId}/${entityId}/billingcycle/${billingCycleId}`
      })
      .then(({ data: billingCycles }) => billingCycles)
  }

  async getBillingCyclesByEntityId(
    blId: string,
    subscriptionId: string,
    entityId: string,
    pageLimit = 1
  ): Promise<BillingCyclePeriodsResponse[]> {
    let returnData: BillingCyclePeriodsResponse[] = []
    let nextPage = ''

    do {
      const { data: pageData } = (await this.jarvisWebHttpInstance.get({
        url: nextPage
          ? nextPage
          : `/${blId}/billingcycles/${subscriptionId}/${entityId}`,
        params: {
          limit: 20
        }
      })) as AxiosResponse<BillingCyclePeriodsResponse>

      returnData = [...returnData, pageData]

      const nextPageLink = pageData?.links?.find(
        (link) => link.rel === 'nextPage'
      )?.href

      nextPage = pageLimit > 1 ? nextPageLink : null
    } while (nextPage)

    return returnData
  }

  async getPlanUsageDetails(
    blId: string,
    subscriptionId: string,
    entityId: string
  ): Promise<BillingCyclePeriodsResponse[]> {
    let returnData: BillingCyclePeriodsResponse[] = []
    const nextPage = ''

    const { data: pageData } = (await this.jarvisWebHttpInstance.get({
      url: nextPage
        ? nextPage
        : `/${blId}/usage/${subscriptionId}/${entityId}?refresh=true`,
      params: {
        limit: 20
      }
    })) as AxiosResponse<BillingCyclePeriodsResponse>
    returnData = [...returnData, pageData]

    return returnData
  }
}
