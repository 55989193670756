import React from 'react'
import { ThemeProvider as StyleThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import { AddOn, Device } from '../types/subscription'
import { TranslatorFunctionType } from '@/types/localization'
import { Panel, AddonsContainer } from './styles'
import { AddOnDevice } from '../AddOnDevice'
import { MasterDeviceDetail } from '../MasterDeviceDetail'
import { SubscriptionOptionsResponse } from '@monetization/hpaip-notification-rules-react'

type SubPanelProps = {
  t: TranslatorFunctionType
  addOns?: AddOn[]
  device?: Device
  belowTitleArea?: React.ReactNode
  isDisabled?: boolean
  printerModelName?: string
  inkPlan?: SubscriptionOptionsResponse
  subscriptionId?: string
  isRemovePaperEnabledFlag?: boolean
  activePaperSubscription?: boolean
  shippingAddress?: any
}

export function SubscriptionIncludesPanel(props: SubPanelProps) {
  const {
    addOns,
    device,
    belowTitleArea,
    t,
    isDisabled,
    printerModelName,
    inkPlan,
    subscriptionId,
    shippingAddress
  } = props as SubPanelProps

  return (
    <StyleThemeProvider theme={useTheme()}>
      <Panel>
        {device && (
          <MasterDeviceDetail
            key={device.name}
            {...device}
            t={t}
            belowTitleArea={belowTitleArea}
            isDisabled={isDisabled}
            printerModelName={printerModelName}
          />
        )}
        {addOns && addOns.length > 0 && (
          <AddonsContainer>
            <AddOnDevice
              isDisabled={isDisabled}
              state={device.state}
              t={t}
              inkPlan={inkPlan}
              subscriptionId={subscriptionId}
              isRemovePaperEnabledFlag={props.isRemovePaperEnabledFlag}
              activePaperSubscription={props.activePaperSubscription}
              shippingAddress={shippingAddress}
            />
          </AddonsContainer>
        )}
      </Panel>
    </StyleThemeProvider>
  )
}
