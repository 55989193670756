const getAllNotificationsObjects = () => {
  const notificationData = {
    notificationsList: [],
    deliquencyStatus: 'none',
    printerData: null,
    instantInkDeliquencyStatus: 'none',
    notificationApiInfo: {
      deviceConnectionState: '',
      deviceCacheData: null,
      instantInkBillingInfo: null,
      paymentMethod: null,
      pendingChanges: null,
      pendingChangesPostCancellation: null,
      returnDetails: null,
      commerceData: null
    }
  }
  return notificationData
}

export default getAllNotificationsObjects
