import styled, { css } from 'styled-components'
import tokens from '@veneer/tokens'
import { DescriptionList } from '../../ui'
import IconInfo from '@veneer/core/dist/esm/scripts/icons/icon_info'

const { size2, size1 } = tokens.layout
const { gray12, gray9 } = tokens.color
const { size0, size2: fontSize2, lineHeight1, lineHeight3 } = tokens.typography

interface StyledDescriptionListProps {
  $withSpacing?: 'true' | 'false'
}
const marginTop = css`
  margin-top: ${size2};
`
export const StyledDescriptionList = styled(
  DescriptionList
)<StyledDescriptionListProps>`
  ${({ $withSpacing }) => ($withSpacing ? marginTop : null)}
`

export const AdditionalFooterDL = styled(StyledDescriptionList)`
  margin-top: -${size1};
`

export const StyledDescriptionListHigh = styled(StyledDescriptionList)`
  color: ${gray12};
  font-size: ${size0};
  line-height: ${lineHeight1};
  color: ${gray9};
`

export const FooterDescriptionList = styled(StyledDescriptionList)`
  font-size: ${fontSize2};
  line-height: ${lineHeight3};
  color: ${gray9};
`

export const TextCenter = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
`

export const TopContainer = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const StyledIconInfo = styled(IconInfo)`
  margin-left: ${size1} !important;
`
