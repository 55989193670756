import { useState, useEffect } from 'react'
import { CommerceBillingCycleClient } from '@/services/CommerceBillingCycleClient'

export const useCommerceDetails = (subId, authProvider, stack) => {
  const [billingData, setBillingData] = useState(null)

  useEffect(() => {
    subId &&
      (async () => {
        const BillingData = new CommerceBillingCycleClient(authProvider, stack)
        try {
          const { data } = await BillingData.getCommerceDetails(subId)

          setBillingData(data)
        } catch (err) {
          setBillingData(null)
          return err
        }
      })()
  }, [subId, authProvider, stack])

  return billingData
}
