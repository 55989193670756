import styled, { css } from 'styled-components'
import tokens from '@veneer/tokens'
import { DescriptionList } from '../../ui'
import IconInfo from '@veneer/core/dist/esm/scripts/icons/icon_info'

const { gray9, gray6 } = tokens.color
const { size2, size0, lineHeight3, lineHeight1 } = tokens.typography
const { size1 } = tokens.layout

interface StyledDescriptionListProps {
  $withSpacing?: 'true' | 'false'
}
const marginTop = css`
  margin-top: 6px;
`
export const StyledDescriptionList = styled(
  DescriptionList
)<StyledDescriptionListProps>`
  color: ${gray9};
  ${({ $withSpacing }) => ($withSpacing ? marginTop : null)}
`

export const StyledDescriptionListTop = styled(DescriptionList)`
  color: ${gray6};
`

export const StyledDescriptionListBig = styled(StyledDescriptionList)`
  font-size: ${size2};
  line-height: ${lineHeight3};
  color: ${gray9};
`

export const FooterDescriptionList = styled(DescriptionList)`
  font-size: ${size0};
  line-height: ${lineHeight1};
  color: ${gray9};
`

export const TextCenter = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
`

export const TopContainer = styled.section`
  padding-bottom: 54px;
`

export const BottomContainer = styled.article`
  padding-top: 3px;
  border-top: 1px solid ${gray9};
`

export const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const StyledIconInfo = styled(IconInfo)`
  margin-left: ${size1} !important;
`
