import { useSuspendedAndOverdueDelinquencyNotification } from '@/hooks/useSuspendedAndOverdueDelinquencyNotification'
import useGetText from '../../../hooks/useGetText'
import { NotificationType } from '@/utils/common-utils'
import { NavigationType } from '@/types/shell'
import { useExpirationDelinquencyNotification } from '@/hooks/useExpirationDelinquencyNotification'
import { SubscriptionStateEnum } from '@/types'
import { DeliquencyNotification } from '@/types/pendingChanges'
import { handleNavigationPath } from '@/utils/handleNavigationPath'
import defaultLanguageJson from '@/assets/locale/en_US.json'

export default function DelinquencyNotification(
  mfe: string,
  activePrinterData: any,
  navigateToBillingHandler?: () => void,
  navigation?: NavigationType,
  allNotificationsObject?: any
) {
  const notificationStatus = useSuspendedAndOverdueDelinquencyNotification(
    activePrinterData,
    allNotificationsObject
  )
  const notification = useExpirationDelinquencyNotification(
    activePrinterData,
    notificationStatus,
    allNotificationsObject
  )

  const deliquencyNotification = notification === 'none' ? null : notification
  const getText = useGetText('delinquency')

  if (
    !deliquencyNotification ||
    activePrinterData?.printer?.state === SubscriptionStateEnum.CANCELED
  ) {
    return null
  }

  const handleRedirect = createHandleRedirect(
    mfe,
    navigateToBillingHandler,
    navigation
  )
  const notificationData = createNotificationData(
    deliquencyNotification,
    activePrinterData,
    getText,
    handleRedirect
  )

  return { notificationData, deliquencyStatus: deliquencyNotification }
}

function createHandleRedirect(
  mfe: string,
  navigateToBillingHandler?: () => void,
  navigation?: NavigationType
) {
  return () => {
    if (mfe === 'ShippingBilling' && navigateToBillingHandler) {
      return navigateToBillingHandler()
    } else {
      if (navigation) {
        return navigation.push(
          handleNavigationPath('/account-details/shipping-billing')
        )
      }
      window.location.href = handleNavigationPath(
        '/account-details/shipping-billing'
      )
      return window
    }
  }
}

function getFormattedDescription(
  deliquencyNotification: DeliquencyNotification | null,
  activePrinterData: any,
  getText: (subkey?: string, defaultValue?: any) => string
): string {
  let notificationType: any = deliquencyNotification
  const entityState = activePrinterData?.printer?.state
  if (
    entityState === SubscriptionStateEnum.CANCELED ||
    entityState === SubscriptionStateEnum.CANCELING
  ) {
    if (deliquencyNotification === DeliquencyNotification.SUSPENDED) {
      notificationType = 'suspended-cancelled'
    }
    if (deliquencyNotification === DeliquencyNotification.OVERDUE) {
      notificationType = 'overdue-cancelled'
    }
  }
  return getText(`${notificationType}.description`, {
    defaultValue: defaultLanguageJson.delinquency[notificationType].description
  })
}

function getNotificationType(
  deliquencyNotification: DeliquencyNotification | null
) {
  return deliquencyNotification === 'suspended' ||
    deliquencyNotification === 'expired'
    ? NotificationType.NEGATIVE
    : NotificationType.WARNING
}

function createNotificationData(
  deliquencyNotification: DeliquencyNotification | null,
  activePrinterData: any,
  getText: (subkey?: string, defaultValue?: any) => string,
  handleRedirect: () => void
) {
  return {
    title: getText(`${deliquencyNotification}.title`, {
      defaultValue:
        defaultLanguageJson.delinquency[deliquencyNotification].title
    }),
    description: getFormattedDescription(
      deliquencyNotification,
      activePrinterData,
      getText
    ),
    showCloseButton: false,
    notificationID: `deliquency-${deliquencyNotification}`,
    notificationType: getNotificationType(deliquencyNotification),
    callbackFunction: handleRedirect
  }
}
