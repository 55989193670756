import { useState, useEffect } from 'react'
import { ReturnStateClient } from '@/services/ReturnStateClient'

export const useFetchReturnData = (tenantId, authProvider, stack) => {
  const [returnData, setReturnData] = useState(null)

  useEffect(() => {
    tenantId &&
      (async () => {
        const ReturnData = new ReturnStateClient(authProvider, stack)
        try {
          const { data } = await ReturnData.getReturnDetails(tenantId)

          setReturnData(data)
        } catch (err) {
          setReturnData('404')
        }
      })()
  }, [tenantId, authProvider, stack])

  return Array.isArray(returnData) && returnData.length > 0
    ? returnData[0]
    : returnData
}
