import {
  Stack,
  StratusClient
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { Address } from '@/types'
import { reloginOnUnauthorized } from '@/utils'
import { getAPIURLS } from '../../helpers/getUrlConfig'
import { getStack } from '../../helpers/getStack'

export class AddressClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(getAPIURLS(getStack(stack)).ADDRESS_URL, authToken)
  }

  async getAddressById(tenantId: string): Promise<Address> {
    if (!tenantId) return
    return this.jarvisWebHttpInstance
      .get({ url: `/addresses/${tenantId}` })
      .then((response) => response.data)
      .catch((err) => {
        reloginOnUnauthorized(err)
        throw err
      })
  }
}
