import { useCallback } from 'react'
import AssetsProviderFactory from '../../../assets/AssetsProviderFactory'
import { getLocation } from '../../../utils/location'

export type Interpolation = {
  [key: string]: string
}

const { language, country } = getLocation()

const useGetText = (prefixKey?: string) => {
  const tLanguage = language || 'en'
  const tCountry = country || 'US'
  const assetsProvider = AssetsProviderFactory.create(tLanguage, tCountry)

  return useCallback(
    (subkey?: string, interpolation?: Interpolation) => {
      const key = [prefixKey, subkey].filter((e) => e).join('.')
      if (interpolation && 'defaultText' in interpolation) {
        return assetsProvider?.getText(key, interpolation) !== key
          ? assetsProvider?.getText(key, interpolation)
          : interpolation['defaultText']
      } else {
        return assetsProvider.getText(key, interpolation) || key
      }
    },
    [assetsProvider, prefixKey]
  )
}

export default useGetText
