import { useEffect, useState } from 'react'

export const usePaperCancelledData = (subscriptionId, pendingData) => {
  const [paperCancelledData, setPaperCancelledData] = useState(null)

  useEffect(() => {
    if (!pendingData || !Array.isArray(pendingData?.contents)) {
      setPaperCancelledData(null)
      return
    }

    const paperCancelledData = pendingData?.contents.find(
      (content) =>
        content.type === 'cancellation' &&
        content.reason.type === 'instantPaperCancellation'
    )

    setPaperCancelledData(paperCancelledData || null)
  }, [pendingData, subscriptionId])

  return paperCancelledData
}
