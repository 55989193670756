import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const formatDate = (locale: string, d: Date) =>
  d.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })

export const formatYearMonth = (locale: string, d: Date) =>
  d.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short'
  })

export const transformToTime = (date: Date | string) => new Date(date).getTime()

export const dateToUTC = (d: Date | string | dayjs.Dayjs): Date =>
  dayjs(d).utc().toDate()
