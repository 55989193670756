import React from 'react'
import { ThemeProvider as StyleThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import IconPrinter from '@veneer/core/dist/esm/scripts/icons/icon_printer'
import { TranslatorFunctionType } from '@/types/localization'
import Images from '@/assets/images'
import { Panel, DeviceInfo, ProductNo, SerialNo, DeviceImage } from './styles'
import Skeleton from '@/components/Skeleton'
import { ProgressContainer } from '@/components/SubscriptionManagement/styles'

type SubscriptionProps = {
  t: TranslatorFunctionType
  subscription: {
    printerModelName?: string
    printerSKU?: string
    printerSerial?: string
    printerImageURL?: string
  }
  isDisabled?: boolean
}

export function SubscriptionIncludesPanelNoDeviceCache(
  props: SubscriptionProps
) {
  const { t, subscription, isDisabled } = props
  const { printerModelName, printerSKU, printerSerial, printerImageURL } =
    subscription

  return (
    <StyleThemeProvider theme={useTheme()}>
      <Panel>
        {!printerSKU ? (
          <ProgressContainer>
            <Skeleton height="5vh" />
          </ProgressContainer>
        ) : (
          <>
            <DeviceInfo isDisabled={isDisabled}>
              <div>
                <IconPrinter size={30} />
                {printerModelName && (
                  <h4 className="title-small">{printerModelName}</h4>
                )}
              </div>
              <div>
                {printerSKU && (
                  <>
                    <ProductNo isDisabled={isDisabled} className="label-small">
                      {t('master-device-detail.product-number', {
                        defaultValue: 'Product number'
                      })}
                    </ProductNo>
                    <span className="caption-small">{printerSKU}</span>
                  </>
                )}
                {printerSerial && (
                  <>
                    <SerialNo isDisabled={isDisabled} className="label-small">
                      {t('master-device-detail.serial-number', {
                        defaultValue: 'Serial number'
                      })}
                    </SerialNo>
                    <span className="caption-small">{printerSerial}</span>
                  </>
                )}
              </div>
            </DeviceInfo>
            <DeviceImage isDisabled={isDisabled}>
              <img
                alt={printerModelName}
                src={printerImageURL}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null
                  currentTarget.src = Images.printerPlaceholder
                }}
              />
            </DeviceImage>
          </>
        )}
      </Panel>
    </StyleThemeProvider>
  )
}
