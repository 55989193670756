import { JarvisAuthProvider } from '@jarvis/web-http'
import { Stack } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { SubscriptionsStateClient } from '../../services/SubscriptionStateClient'
import { SubscriptionsClient } from '@/services/SubscriptionsClient'
import { getStack } from '../../helpers/getStack'
import { getVariables } from '../../helpers/getUrlConfig'
import { FulfillmentClient } from '../../../src/services/FulfillmentClient'
import {
  Subscription,
  SubscriptionEntity,
  SubscriptionEntityTypeEnum,
  SubscriptionOptionsResponse
} from '@/types'
import { getActivePrinter } from './printerHelper'
import {
  getAddPaperInfo,
  getRemovePaperEnabled,
  getActivePaperSubscription
} from './paperHelper'
import { getCancellationInfo } from './cancellationHelper'
import isDeviceShippedOrDelivered from '@/utils/isDeviceShippedOrDelivered'

export interface PrinterSubscription {
  fullfillmentData: any
  subscriptionId: string
  printer: SubscriptionEntity
  addons: SubscriptionEntity[]
  instantInk: SubscriptionEntity
  root: Subscription
  replacement: boolean
  paperInfo: any
  optionsData: SubscriptionOptionsResponse
  isRemovePaper: boolean
  pendingData: any
  activePaperSubscription: boolean
  cancellationInfo: any
  isDeviceShipped: boolean
}

export const getOptionsData = async (
  authProvider: JarvisAuthProvider,
  stack: Stack,
  subsId: string,
  entityId: string
) => {
  const subscriptionsClient = new SubscriptionsClient(authProvider, stack)
  try {
    const blId = getVariables(getStack(stack)).BL_ID
    const optionsData = await subscriptionsClient.getSubscriptionOptions(
      subsId,
      blId,
      entityId
    )

    return optionsData
  } catch (e) {
    return null
  }
}

const getPendingChanges = async (subscriptionsClient, subsId) => {
  try {
    const { data } = await subscriptionsClient.getPendingChanges(subsId)
    if (data) {
      return data
    } else {
      return null
    }
  } catch {
    return null
  }
}

const getFulfilmentOrderByFulfillmentOrderId = async (
  subscriptionId: string,
  authProvider: JarvisAuthProvider,
  stack: Stack,
  fulfillmentDetails: any
) => {
  const fulfillmentClient = new FulfillmentClient(authProvider, stack)
  try {
    const entityFulfilment =
      await fulfillmentClient.getFulfilmentOrderByFulfillmentOrderId(
        fulfillmentDetails?.value?.fulfillmentOrderId,
        subscriptionId
      )
    if (entityFulfilment) {
      return entityFulfilment
    } else {
      return null
    }
  } catch (error) {
    console.error('Error fetching fulfillment orders:', error)
    return null
  }
}

export async function fetchActivePrinterSubscription(
  authProvider: JarvisAuthProvider,
  stack: Stack,
  subsId: string,
  enableBuyerRemorseCancellation: any
): Promise<PrinterSubscription> {
  const subscriptionsClient = new SubscriptionsStateClient(authProvider, stack)
  try {
    const { data } = await subscriptionsClient.getSubscriptionById(subsId)
    const { subscriptionId, entities } = data

    const { printer, replacement } = await getActivePrinter(entities as any)

    if (!printer) throw new Error('printer subscription not found')

    const addons = entities.filter((entity) =>
      printer.linkedEntityIds?.includes(entity.entityId)
    )

    const instantInk = entities.find(
      (entity) => entity.entityType === SubscriptionEntityTypeEnum.INK
    )

    const optionsData = await getOptionsData(
      authProvider,
      stack,
      subscriptionId,
      printer?.entityId
    )

    const addPaperInfo = await getAddPaperInfo(optionsData)
    const isRemovePaper = await getRemovePaperEnabled(entities)
    const activePaperSubscription = await getActivePaperSubscription(entities)
    const pendingChanges = await getPendingChanges(subscriptionsClient, subsId)
    const fullfillmentData = await getFulfilmentOrderByFulfillmentOrderId(
      subscriptionId,
      authProvider,
      stack,
      printer?.fulfillment
    )
    const isDeviceShipped = isDeviceShippedOrDelivered(
      fullfillmentData,
      printer
    )
    const cancellationInfo = await getCancellationInfo(
      data.state,
      printer,
      pendingChanges,
      enableBuyerRemorseCancellation,
      isDeviceShipped
    )

    return {
      subscriptionId, // TO DO: Remove and replace with root.subscriptionId this uses
      printer,
      addons: addons as unknown as SubscriptionEntity[],
      instantInk: instantInk as unknown as SubscriptionEntity,
      root: data as unknown as Subscription,
      replacement,
      paperInfo: addPaperInfo,
      optionsData: optionsData?.data as SubscriptionOptionsResponse,
      isRemovePaper,
      pendingData: pendingChanges,
      activePaperSubscription,
      cancellationInfo,
      fullfillmentData: fullfillmentData,
      isDeviceShipped
    }
  } catch (e) {
    const message = e?.toString() || 'an unknown error occurred'

    throw new Error(
      `[fetchActivePrinterSubscription]${
        e instanceof Error ? '' : ' Error:'
      } ${message}`
    )
  }
}
