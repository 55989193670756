import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
import Accordion from '@veneer/core/dist/scripts/accordion/accordion'
import Modal from '@veneer/core/dist/scripts/modal/modal'

const { white } = tokens.color
const { lineHeight2, size2 } = tokens.typography
const { size5: layoutSize5, size4: layoutSize4 } = tokens.layout
const { layout, color, typography } = tokens

export const AccordionHeaderTitle = styled.h4`
  && {
    font-weight: normal;
    font-size: ${size2};
    line-height: ${lineHeight2};
  }
`

export const StyledAccordion = styled(Accordion)`
  && {
    height: fit-content;
    background: ${white};
    padding: ${layoutSize4};
    padding-left: ${layoutSize5};

    div {
      line-height: ${lineHeight2};
      margin-left: 0;
      .vn-animated-height__container {
        padding: 0 12px;
        transition: all 0.5s ease-in-out !important;
        max-height: 0;

        &.vn-animated-height__container--entered {
          max-height: 500px !important;
        }
      }
      .vn-animated-height__wrapper-inner > div {
        width: 100%;
      }
    }
  }
`

export const StyleModal = styled(Modal)`
  && {
    .vn-modal__close > span > svg {
      color: ${color.gray12};
    }
    .vn-modal--content {
      padding: ${layout.size0};
      max-width: 750px;
    }
    .vn-modal--footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: ${layout.size6} 55px;
      max-width: 750px;

      button {
        @media (max-width: ${layout.mdMin}) {
          width: max-content;
        }
        @media (max-width: ${layout.xsMax}) {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: ${layout.mdMax}) {
    .vn-modal--content {
      width: 75%;
    }
  }
  @media (max-width: ${layout.smMin}) {
    .vn-modal--content {
      width: 90%;
    }
  }
`

export const FAQModalHeader = styled.div`
  display: flex;
  padding: ${layout.size8} 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: ${layout.size0};
  align-self: stretch;
  border-radius: ${layout.size4} ${layout.size4} ${layout.size0} ${layout.size0};
  border-bottom: 1px solid ${color.gray12};
  background: #e6e6e6;
  @media (max-width: 576px) {
    padding: ${layout.size8} ${layout.size4};
    > img {
      width: 200px;
    }
  }
`

export const Title = styled.div`
  color: ${color.gray12};
  font-family: 'Forma DJR UI';
  font-size: ${typography.size9};
  font-style: normal;
  font-weight: 400;
  line-height: ${typography.lineHeight9};
  margin-top: ${layout.size4};
  @media (max-width: 576px) {
    font-size: ${typography.size7};
  }
`

export const SubTitle = styled.div`
  color: ${color.gray12};
  font-family: 'Forma DJR UI';
  font-size: ${typography.size3};
  font-style: normal;
  font-weight: 400;
  line-height: ${typography.size5};
  margin-top: ${layout.size2};
`

export const ModalBody = styled.div`
  padding: 0px ${typography.size9};
  max-height: 320px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
  @media (max-width: 576px) {
    padding: ${layout.size8} ${layout.size4} ${layout.size0} ${layout.size4};
  }
`
