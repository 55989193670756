const defaultText = {
  title: 'Add paper to your plan',
  description:
    'Never run out of paper again¹ when you add the Paper Add-on Service to your HP All-In Plan.',
  featureOne:
    'We’ll deliver paper for your HP All-In printer to your door automatically², just like your ink',
  featureTwo:
    'You’ll receive paper based on your usage, and only when you need it',
  featureThree:
    'Our 8.5x11in., 20lb, 96 Bright HP Paper is ideal for everyday color printing needs and is certified by the Forest Stewardship Council©³xxx',
  faq: 'Frequently asked questions',
  tos: 'Terms of service',
  listText1:
    '¹Based on plan usage, Internet connection to eligible HP printer, valid credit/debit card, email address, and delivery service in your geographic area. Paper Add-On is only available in the continental U.S. excluding Alaska, Hawaii, U.S. territories, military bases and P.O. box addresses. Paper can be individually removed from your HP All-In plan at any time. HP only monitors pages printed through your HP All-In Plan printer subscribed to this Paper Add-on Service. The paper we send should only be used for printing purposes in this subscribed printer. If you use the paper we send for any other purpose other than printing in your subscribed printer, you run the risk of running out of paper without HP knowing.',
  listText2:
    '²Printers require direct connection to the Internet for program participation. Internet access sold separately.',
  listText3:
    '³HP has a policy for offering Forest Steward Council® (FSC®)-certified papers that are sourced from FSC®‑certified forests and other controlled sources. HP trademark license code FSC®-C017543, see fsc.org. Sylvamo Corporation trademark license codes FSC®-C168336 (North America), FSC®-C101994, FSC®-C084255, and FSC®-CO22974 (Europe). Not all FSC®-certified products are available in all regions, look for logo on pack.',

  btn1Label: 'Not now',
  btn2Label: 'Add paper'
}

export const getDefaultText = (key: string) => {
  return defaultText[key]
}

export const getDefaultCheckboxText = (
  pagesPerMonth: string,
  pricePlan: string
) =>
  `Yes, add paper to my existing ${pagesPerMonth}-page HP All In Plan for ${pricePlan}/month`
