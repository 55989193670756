import moment from 'moment'

export const formatDaysToReturn = async (
  dateToFormat: string | null,
  daysToReturn: number
): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      resolve(
        dateToFormat
          ? moment(dateToFormat).add(daysToReturn, 'days').format('MMM DD')
          : '.'
      )
    } catch (error) {
      reject(error)
    }
  })
}

export const returnDate = async (
  billingDate: string | null,
  cancelledOn: string | null,
  customerEndDate: string | null
): Promise<string | null> => {
  return new Promise((resolve) => {
    let result: string | null
    if (customerEndDate !== null) {
      result = customerEndDate
    } else if (billingDate !== null) {
      result = billingDate
    } else if (cancelledOn !== null) {
      result = cancelledOn
    } else {
      result = null
    }
    resolve(result)
  })
}
