import tokens from '@veneer/tokens'
import styled, { css } from 'styled-components'

const { size4, smMax, size2 } = tokens.layout

interface ChildRowProps {
  $preserveLayoutOnMobile?: boolean
  $preventHorizontalStretch?: boolean
  $columnSize?: number
}

interface ContainerRowProps extends ChildRowProps {
  $horizontalAlign: string
}

export const ContainerRow = styled.div<ContainerRowProps>`
  display: flex;
  justify-content: ${({ $horizontalAlign }) => $horizontalAlign};
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
  gap: ${size4};

  ${({ $preserveLayoutOnMobile }) => {
    if (!$preserveLayoutOnMobile) {
      return css`
        @media screen and (max-width: ${smMax}) {
          flex-wrap: wrap;
        }
      `
    }
  }};
`

export const ChildRow = styled.div<ChildRowProps>`
  display: flex;
  align-items: stretch;
  justify-content: center;
  box-sizing: border-box;

  & > *:not(img) {
    width: 100%;
  }

  ${({ $preserveLayoutOnMobile }) => {
    if (!$preserveLayoutOnMobile) {
      return css`
        @media screen and (max-width: ${smMax}) {
          width: 100%;
        }
      `
    }
  }}

  ${({ $preventHorizontalStretch, $columnSize }) => {
    if (!$preventHorizontalStretch) {
      return css`
        width: 100%;
        flex-grow: 1;
      `
    }

    return css`
      width: calc((100% / ${$columnSize}) - ${size2});
    `
  }};
`
