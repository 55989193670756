import React from 'react'
import { DefaultExportProps } from '../../../types'
import {
  StyledDescription,
  StyledDescriptionList,
  StyledTerm
} from './DescriptionList.styles'

export type Direction = 'column' | 'row'

export interface DescriptionListProps extends Partial<DefaultExportProps> {
  description: string | React.ReactNode
  value?: string | React.ReactNode
  direction?: Direction
}

export const DescriptionList: React.FunctionComponent<DescriptionListProps> = ({
  value,
  description,
  className,
  direction = 'row'
}) => {
  return (
    <StyledDescriptionList className={className} $direction={direction}>
      <StyledTerm>{description}</StyledTerm>
      {value && <StyledDescription>{value}</StyledDescription>}
    </StyledDescriptionList>
  )
}
