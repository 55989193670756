import styled from 'styled-components'
import { smartQueries } from '@/utils/device'
import { tokens } from '@veneer/tokens'
const { size2, size5, size7, lineHeight7, lineHeight3 } = tokens.typography
const { gray12, white } = tokens.color
const { size0, xsMax } = tokens.layout
export const Container = styled.div`
  color: ${({ theme }) => theme.colorForegroundMedium};
  font-family: ${({ theme }) => theme.fontTextRegular};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${lineHeight7};

  @media screen and (max-width: ${xsMax}) {
    padding: ${lineHeight7} ${size5};
  }
`
export const Heading = styled.div`
  font-size: ${size7};
  line-height: ${lineHeight7};
  margin-bottom: ${lineHeight3};
`

export const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
  background: ${white};
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  font-size: ${size2};
  text-align: center;
  letter-spacing: ${size0};
  color: ${gray12};
  min-height: 532px;
  ${smartQueries.mobileXS(`
    min-height: 250px;
  `)}
`
export const ProgressContainer = styled.div`
  padding-bottom: 15px;
  width: 100%;
`
