import styled, { css } from 'styled-components'
import { tokens } from '@veneer/tokens'
import IconChevronRight from '@veneer/core/dist/esm/scripts/icons/icon_chevron_right'

interface AddOnDeviceProps {
  isDisabled?: boolean
}

const { cornerRadius2, size4, size3, size2 } = tokens.layout
const { lineHeight2 } = tokens.typography
const { highContrastGray, gray12, gray9 } = tokens.color

const linkStyles = css`
  font-size: ${size4}!important;
  line-height: ${lineHeight2}!important;
  display: flex !important;
  width: 100% !important;
  align-items: center;
  justify-content: space-between;
  color: #0076ad;
  cursor: pointer;
  &:focus {
    border: 1px solid #0278ab;
    outline: none;
  }
`

export const BoosterCardPanel = styled.div<AddOnDeviceProps>`
  background-color: ${({ theme }) => theme.colorBackground};
  color: ${({ theme }) => theme.colorForegroundMedium};
  border-radius: ${cornerRadius2};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${size4};
  background-color: #fafafa;
`
export const LabelValuePair = styled.div`
  margin-bottom: ${size3};
  &:last-child {
    margin-bottom: 0;
  }
  > span:nth-child(even) {
    color: ${({ theme }) => theme.colorForegroundLight};
  }

  > span {
    display: block;
  }
`
export const BoosterImgContainer = styled.div`
  align-self: center;
  .boosterIcon {
    margin-bottom: ${size2};
  }
  > img {
    margin-top: 0px;
    max-width: 300px;
    min-width: 100px;
    max-height: 140px;
  }
`
export const ImgHolder = styled.div`
  align-self: center;
  width: 70px;
`
export const LabelDarkBlock = styled.div`
  span {
    color: ${(props) => (props.isDisabled ? highContrastGray : gray9)};
  }
  svg {
    color: ${(props) => (props.isDisabled ? highContrastGray : gray9)};
  }
  > span {
    color: ${(props) => (props.isDisabled ? highContrastGray : gray9)};
    > svg {
      color: ${(props) => (props.isDisabled ? highContrastGray : gray12)};
    }
    display: block;
    margin-bottom: ${size3};
    &:last-child {
      margin-bottom: 0;
    }
  }
`
export const PrintPlanBlock = styled.div<AddOnDeviceProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 0.5rem;
  color: ${(props) => (props.isDisabled ? highContrastGray : gray9)};
  > svg {
    color: ${(props) => (props.isDisabled ? highContrastGray : gray12)};
  }
  span.label-small {
    padding-bottom: 26px;
  }
`
export const FooterWrapper = styled.div`
  ${linkStyles}
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #dbdbdb;
  ${({ disabled }) =>
    !!disabled &&
    css`
      pointer-events: none;
      color: ${highContrastGray} !important;
      & > svg {
        color: ${highContrastGray} !important;
      }
    `}
`

export const ChevronRightIcon = styled(IconChevronRight)`
  color: #0076ad !important;
  ${({ disabled }) =>
    !!disabled &&
    css`
      pointer-events: none;
      color: ${highContrastGray} !important;
      & > svg {
        color: ${highContrastGray} !important;
      }
    `}
`

export const ErrorButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${size2};
  width: 100%;
`
