import { SubscriptionStateEnum } from '@/types'
import checkCancellationTriggered from '@/utils/pendingChangesDataUtil'

export const getCancellationInfo = async (
  rootState,
  printer,
  pendingData,
  enableBuyerRemorseCancellation,
  isDeviceShipped
) => {
  let showCancelPlan: boolean,
    showCancellationStatus: boolean,
    buttonDisable: boolean,
    firstHourCancellation: boolean

  const updateCancellationState = (
    cancelPlan,
    cancellationStatus,
    disableButton,
    firstHour
  ) => {
    showCancelPlan = cancelPlan
    showCancellationStatus = cancellationStatus
    buttonDisable = disableButton
    firstHourCancellation = firstHour
  }

  if (
    enableBuyerRemorseCancellation &&
    rootState === SubscriptionStateEnum.PENDING
  ) {
    updateCancellationState(true, false, false, true)
  } else {
    handleEntityState(
      printer,
      enableBuyerRemorseCancellation,
      pendingData,
      updateCancellationState,
      isDeviceShipped
    )
  }

  return {
    showCancelPlan,
    showCancellationStatus,
    buttonDisable,
    firstHourCancellation
  }
}

const handleEntityState = async (
  printer,
  enableBuyerRemorseCancellation,
  pendingData,
  updateCancellationState,
  isDeviceShipped
) => {
  switch (printer?.state) {
    case SubscriptionStateEnum.CANCELED:
      updateCancellationState(false, false, false, false)
      break
    case SubscriptionStateEnum.CANCELING:
      updateCancellationState(false, true, false, false)
      break
    case SubscriptionStateEnum.SUBSCRIBED:
    case SubscriptionStateEnum.SUSPENDED:
      updateCancellationState(true, false, false, false)
      break
    case SubscriptionStateEnum.PENDING:
      if (enableBuyerRemorseCancellation) {
        const isCancellationTriggered = checkCancellationTriggered(pendingData)
        updateCancellationState(
          !isCancellationTriggered,
          Boolean(isCancellationTriggered),
          false,
          false
        )
      } else {
        const cancellationState = isDeviceShipped
          ? [true, false, false, false]
          : [true, false, true, false]
        updateCancellationState(...cancellationState)
      }
      break
    default:
      break
  }
}
