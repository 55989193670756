import useGetText from '../../hooks/useGetText/useGetText'
import { handleRedirect } from '../../utils/handleNavigationPath'
import defaultLanguageJson from '../../assets/locale/en_US.json'
import { NotificationType, NotificationID } from '../../utils/common-utils'
interface Notification {
  title: string
  description: string
  showCloseButton: boolean
  notificationID: string
  notificationType: string
  SecondCallbackFunction?: () => void // Optional
}
const GetAllPrearrivalNotification = (
  mfeName: string,
  printer: any,
  enableBuyerRemorseCancellation: boolean | null,
  navigation: any,
  subscriptionId: string | null,
  isDeviceShipped: boolean | null,
  pendingData: any
): Notification | {} => {
  const entityState = printer?.state

  const getText = useGetText('preArrival')

  if (entityState !== 'pending') {
    return null
  }

  if (!enableBuyerRemorseCancellation || isDeviceShipped) {
    const notification = getDeviceShippedPrearrivalNotification(
      mfeName,
      getText
    )
    return notification || null // Return the notification or null directly
  }

  if (enableBuyerRemorseCancellation) {
    const preShipContent = pendingData?.contents?.find(
      (content) =>
        content?.type === 'cancellation' &&
        content?.reason?.value?.preShip === 'true'
    )

    const isPreShip = !!preShipContent

    const notification = isPreShip
      ? getBuyerRemorseCancellationStatusNotification(
          mfeName,
          getText,
          subscriptionId,
          navigation
        )
      : getBuyerRemorseCancelNotification(
          mfeName,
          getText,
          subscriptionId,
          navigation
        )

    return notification || null
  }
}

function getBuyerRemorseCancellationStatusNotification(
  mfeName,
  getText,
  subscriptionId,
  navigation
) {
  switch (mfeName) {
    case 'overview':
    case 'updatePlan':
    case 'Trackshipments':
    case 'Subscriptions':
      return {
        title: getText('preShip.afterCancel.title', {
          defaultValue: defaultLanguageJson.preArrival.preShip.afterCancel.title
        }),

        description: getText('preShip.afterCancel.description', {
          defaultValue:
            defaultLanguageJson.preArrival.preShip.afterCancel.description
        }),

        showCloseButton: false,

        notificationID: NotificationID.PRESHIPPED_AFTERCANCEL,

        notificationType: NotificationType.INFORMATIVE,

        SecondCallbackFunction: () => handleRedirect(subscriptionId, navigation)
      }

    default:
      return null
  }
}
function getBuyerRemorseCancelNotification(
  mfeName,
  getText,
  subscriptionId,
  navigation
) {
  switch (mfeName) {
    case 'overview':
    case 'updatePlan':
    case 'Trackshipments':
    case 'Subscriptions':
      return {
        title: getText('preShip.beforeCancel.title', {
          defaultValue:
            defaultLanguageJson.preArrival.preShip.beforeCancel.title
        }),

        description: getText('preShip.beforeCancel.description', {
          defaultValue:
            defaultLanguageJson.preArrival.preShip.beforeCancel.description
        }),

        showCloseButton: false,

        notificationID: NotificationID.PRESHIPPED_BEFORECANCEL,

        notificationType: NotificationType.INFORMATIVE,

        SecondCallbackFunction: () => handleRedirect(subscriptionId, navigation)
      }

    default:
      return null
  }
}

function getDeviceShippedPrearrivalNotification(mfeName, getText) {
  switch (mfeName) {
    case 'overview':
      return {
        title: getText('overview.title', {
          defaultValue: defaultLanguageJson.preArrival.overview.title
        }),

        description: getText('overview.description', {
          defaultValue: defaultLanguageJson.preArrival.overview.description
        }),

        showCloseButton: true,

        notificationID: NotificationID.OVERVIEW_PREARRIVAL,

        notificationType: NotificationType.POSITIVE
      }

    case 'updatePlan':
      return {
        title: getText('updatePlan.title', {
          defaultValue: defaultLanguageJson.preArrival.updatePlan.title
        }),
        description: '',
        showCloseButton: false,
        notificationID: NotificationID.UPDATEPLAN_PREARRIVAL,
        notificationType: NotificationType.INFORMATIVE
      }

    case 'Trackshipments':
      return {
        title: getText('Trackshipments.title', {
          defaultValue: defaultLanguageJson.preArrival.Trackshipments.title
        }),
        description: getText('Trackshipments.description', {
          defaultValue:
            defaultLanguageJson.preArrival.Trackshipments.description
        }),
        showCloseButton: false,
        notificationID: NotificationID.TRACKSHIPMENTS_PREARRIVAL,
        notificationType: NotificationType.INFORMATIVE
      }

    case 'Subscriptions':
      return {
        title: getText('Subscriptions.title', {
          defaultValue: defaultLanguageJson.preArrival.Subscriptions.title
        }),

        description: getText('Subscriptions.description', {
          defaultValue: defaultLanguageJson.preArrival.Subscriptions.description
        }),

        showCloseButton: true,

        notificationID: NotificationID.SUBSCRIPTION_PREARRIVAL,

        notificationType: NotificationType.POSITIVE
      }

    default:
      return null
  }
}

export default GetAllPrearrivalNotification
