export interface PaymentIssue {
  pendingChangeId: string
  subscriptionId: string
  entityId: string
  type: string
  value: {
    state: string
    at: string
  }
  initiator: {
    type: string
    value: {
      clientId: string
    }
  }
  reason: {
    type: string
    value: {
      attribute_1: string
      attribute_2: string
    }
  }
  createdAt: string
  updatedAt: string
}

export interface PaginatedResponse {
  version: string
  totalCount: number
  maxPaginationLimit: number
  defaultPaginationLimit: number
  contents: PaymentIssue[]
  links: {
    rel: string
    href: string
  }[]
}
interface Part {
  serialNumber: string
  status: string
  timeStamp: string
  waybillGenerated: boolean
  modelSku: string
  history: History[]
}
export interface RetrieveReturnResponse {
  subscriptionId: string
  returnOrderId: string
  fulfillmentOrderId: string
  tenantId: string
  trackingNumber?: string
  returnNumber: string
  eClaimsID: string
  createdAt: string
  timeoutInDays: number
  returnReason: string
  parts: Part[]
  invoiceNumber?: string
  invoiceCurrency?: string
  invoiceTotal?: string
}

export interface CommerceClientResponse {
  billingDate?: ''
  expiresOn?: ''
  isRecurring?: boolean
  isTrial?: boolean
  trialStartsOn?: ''
  trialEndsOn?: ''
  graceEndsOn?: ''
  pausedOn?: ''
  cancelledOn?: ''
  activatedOn?: ''
  productPrice?: number
  customerEndDate?: ''
  customerStartDate?: ''
  startDate?: ''
}
export enum DeliquencyNotification {
  OVERDUE = 'payment_overdue',
  SUSPENDED = 'suspended',
  INACTIVE = 'no_longer_active',
  EXPIRED = 'expired',
  ABOUT_TO_EXPIRED = 'about_to_expire',
  NONE = 'none'
}
