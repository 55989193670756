import IconInfo from '@veneer/core/dist/esm/scripts/icons/icon_info'
import ProgressIndicator from '@veneer/core/dist/esm/scripts/progress_indicator'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

const { gray12, white, gray9 } = tokens.color
const { size1, lineHeight2, size2, size0, lineHeight1 } = tokens.typography
const { size2: space2, size1: space1 } = tokens.layout

export const GenericText = styled.p`
  font-size: ${size1};
  line-height: ${lineHeight2};
  margin: 0;
`
export const ProgressBarLabel = styled(GenericText)`
  display: flex;
  justify-content: space-between;
  font-size: ${size2};
  line-height: ${lineHeight2};
  margin-bottom: ${space2};
  color: ${gray12};
`

type ColorProp = {
  $color: string
}

const applyColor = ({ $color }: ColorProp) => $color

export const ProgressBarQuantity = styled.span<ColorProp>`
  font-size: ${size1};
  line-height: ${lineHeight2};
  font-weight: 400;
  color: ${gray12};
`

export const ProgressBarDescription = styled(GenericText)`
  font-size: ${size0};
  line-height: ${lineHeight1};
  margin-top: ${space2};
`

export const StyledIconInfo = styled(IconInfo)`
  vertical-align: middle;
  margin-left: ${space1};
`

export const TooltipContent = styled.div`
  max-width: 200px;
  text-align: center;
`
export const ProgressIndicatorWrapper = styled.div<{
  $hasMarkerLabel: boolean
}>`
  position: relative;
  ${({ $hasMarkerLabel }) => ($hasMarkerLabel ? 'padding-bottom: 27px;' : '')}
`

export const StyledProgressIndicator = styled(ProgressIndicator)<ColorProp>`
  && {
    [role='progressbar'] {
      border-radius: 4px;
      height: 8px;

      & > :last-child {
        border-radius: 4px;
        background: ${applyColor};
      }
    }
  }
`

export const Tag = styled.div<{ $position: string; $hasLabel: boolean }>`
  position: absolute;
  top: 0;
  left: ${({ $position }) => $position}%;
  transform: translateX(-50%);
  width: 4px;
  height: 8px;
  background: ${white};

  :after {
    ${({ $hasLabel }) => ($hasLabel ? 'content: "";' : '')}
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 1px;
    height: 9px;
    background: ${gray9};
  }
`

export const Label = styled.label<{ $position: string }>`
  position: absolute;
  bottom: 0;
  left: ${({ $position }) => $position};
  transform: translateX(-50%);
  font-size: ${size0};
  line-height: ${lineHeight2};
  white-space: nowrap;
`
