import React from 'react'
import {
  AccordionHeaderTitle,
  FAQModalHeader,
  ModalBody,
  StyleModal,
  StyledAccordion,
  SubTitle,
  Title
} from './styles'
import Images from '../../../assets/images'
import useGetText from '../../hooks/useGetText'
import Button from '@veneer/core/dist/scripts/button/button'
import { getDefaultText } from './defaultText'
import Markdown from 'markdown-to-jsx'

interface FaqModalProps {
  show: boolean
  onClose: () => void
}

export const FaqModal: React.FunctionComponent<FaqModalProps> = ({
  show,
  onClose
}) => {
  const getText = useGetText('faq-modal')

  const accordianData = Array.from({ length: 8 }, (_, index) => ({
    id: getText(`accordion-data.id-${index + 1}`, {
      defaultText: getDefaultText(`accordion-data.id-${index + 1}`)
    }),
    header: (
      <span>
        {getText(`accordion-data.id-${index + 1}.header`, {
          defaultText: getDefaultText(`accordion-data.id-${index + 1}.header`)
        })}
      </span>
    ),
    content: (
      <p>
        {getText(`accordion-data.id-${index + 1}.content`, {
          defaultText: getDefaultText(`accordion-data.id-${index + 1}.content`)
        })}
      </p>
    ),
    expanded: false
  }))

  const accordionItems = accordianData.map((info, i) => ({
    header: {
      startArea: (
        <AccordionHeaderTitle>
          {info.header.props.children}
        </AccordionHeaderTitle>
      )
    },
    id: `${i}`,
    content: <Markdown>{info.content.props.children}</Markdown>,
    expanded: false
  }))

  const [faqData, setFaqData] = React.useState(accordionItems)

  const handleExpand = (_, id) => {
    setFaqData((prevData) =>
      prevData.map((item, index) =>
        index === id ? { ...item, expanded: true } : item
      )
    )
  }

  const handleCollapse = (_, id) => {
    setFaqData((prevData) =>
      prevData.map((item, index) =>
        index === id ? { ...item, expanded: false } : item
      )
    )
  }

  const handleClose = () => {
    setFaqData(accordionItems)
    onClose()
  }

  return (
    <StyleModal
      show={show}
      closeButton
      onClose={handleClose}
      footer={
        <Button data-testid="close" onClick={handleClose}>
          {getText(`btn-label`, { defaultText: getDefaultText('btn-label') })}
        </Button>
      }
      data-testid="faq-modal"
    >
      <FAQModalHeader>
        <img src={Images.AllInPlan} className="icons" alt="Hp All In Plan" />
        <Title>
          {getText('main-title', { defaultText: getDefaultText('main-title') })}
        </Title>
        <SubTitle>
          {getText('sub-title', { defaultText: getDefaultText('sub-title') })}
        </SubTitle>
      </FAQModalHeader>
      <ModalBody>
        <StyledAccordion
          appearance="outlined"
          items={faqData}
          id="accordion-one-item"
          onExpand={handleExpand}
          onCollapse={handleCollapse}
        />
      </ModalBody>
    </StyleModal>
  )
}
