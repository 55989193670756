import Images from '@/assets/images'
import { InstantInkManagementLinks } from '@/components/InstantInkManagementLinks'
import SubscriptionAccordion from '@/components/SubscriptionAccordion'
import useSettings from '@/hooks/useSettings'
import React from 'react'
import {
  AccordionHeader,
  Container,
  Panel,
  SubscriptionDesc,
  PaymentDetails,
  GPayPayment
} from './styles'
import useInstantInkPaymentInfo from '@/hooks/usePaymentMethodsInfo/usePaymentMethodsInfo'
import Skeleton from '@/components/Skeleton'
import { logos } from '@/components/shared/PaymentInfo/helper'
import { PaymentInfoStyle } from '../shared/PaymentInfo/styles'
import { SubscriptionIncludesPanelNoDeviceCache } from '../shared/SubscriptionIncludesPanelNoDeviceCache'
import { ProgressContainer } from '../SubscriptionManagement/styles'
import { useFlags } from 'launchdarkly-react-client-sdk'
export default function InstantInkSubscription(props) {
  const {
    data: { subscription },
    t
  } = props
  const { assetsProvider } = useSettings()
  const language = assetsProvider.getLanguage()
  const country = assetsProvider.getCountry()
  const { data: info, isLoading } = useInstantInkPaymentInfo()
  const { enableIiGoogleWallet } = useFlags()
  const gPayAvailable =
    info?.creditCard?.cardSource === 'GPay' && enableIiGoogleWallet
  const isDisabled = subscription.state === 'obsolete'

  if (isLoading) {
    return (
      <ProgressContainer>
        <Skeleton height="5vh" data-testid="skeleton" />
      </ProgressContainer>
    )
  }
  return (
    <Container>
      <SubscriptionAccordion
        header={{
          centralArea: (
            <>
              <img
                id="instantInkIcon"
                src={Images.imgInstantInk2}
                alt="instantInkIcon"
              ></img>
              <span className="insinksubid">
                {t('subscriptionId')}: {subscription.accountIdentifier}
              </span>
            </>
          ),
          previewArea: (
            <>
              <AccordionHeader>
                <SubscriptionDesc className="caption">
                  <PaymentInfoStyle>
                    <div className="paymentInfo-top">
                      {subscription?.printerFriendlyName ? (
                        <span>{subscription.printerFriendlyName}</span>
                      ) : (
                        subscription?.printerModelName && (
                          <span>{subscription.printerModelName}</span>
                        )
                      )}

                      {subscription.nextChargeDate && (
                        <span>
                          {t('nextPaymentDue')}: {subscription.nextChargeDate}
                        </span>
                      )}
                    </div>
                  </PaymentInfoStyle>
                  <div className="links-card">
                    <InstantInkManagementLinks
                      {...props}
                      subscription={subscription}
                      country={country}
                      language={language}
                    />
                    <PaymentDetails>
                      {gPayAvailable && (
                        <GPayPayment>
                          <div>via</div>
                          <img src={Images.gPay} alt="gPay" />
                        </GPayPayment>
                      )}
                      <img
                        className="credit-card-type"
                        src={logos[info?.creditCard?.cardTypeKey]}
                      />
                      {info?.creditCard?.cardNumber ? (
                        <div className="last-4-digits">
                          XXXX-
                          {info?.creditCard?.cardNumber?.slice(-4)}
                        </div>
                      ) : (
                        <></>
                      )}
                    </PaymentDetails>
                  </div>
                </SubscriptionDesc>
              </AccordionHeader>
            </>
          ),
          'aria-label': 'instant-ink-subscription'
        }}
        body={
          <Panel>
            <SubscriptionIncludesPanelNoDeviceCache
              t={t}
              subscription={subscription}
              isDisabled={isDisabled}
            />
          </Panel>
        }
        expanded={true}
        id="instantInkSubscriptionAccordion"
        dataTestid="data-test-instantInkSubscription-accordion"
        subscription={subscription}
      />
    </Container>
  )
}
