import { useState, useEffect, useMemo } from 'react'
import { DeviceClient } from '../../services/DeviceClient'
import getApplicationStack from '../../helpers/getApplicationStack'
import getAuthProvider from '../../helpers/getAuthProvider'

export const useGetDeviceClient = (uniqueDeviceId, entityState) => {
  const [deviceCacheData, setDeviceCacheData] = useState(null)
  const authProvider = getAuthProvider()
  const stack = getApplicationStack()

  const deviceClient = useMemo(() => new DeviceClient(authProvider, stack), [
    authProvider,
    stack
  ])
  useEffect(() => {
    uniqueDeviceId &&
      entityState === 'active' &&
      (async () => {
        try {
          const deviceClientData = await deviceClient.getDeviceCacheInfo(
            uniqueDeviceId
          )
          setDeviceCacheData(deviceClientData)
        } catch (error) {
          console.error('error retrieving device connection state: ', error)
        }
      })()
  }, [uniqueDeviceId, deviceClient, entityState])

  return deviceCacheData
}
