import { useEffect, useState } from 'react'
import { DeliquencyNotification } from '@/types/pendingChanges'

export const useSuspendedAndOverdueDelinquencyNotification = (
  activePrinterData,
  allNotificationsObject
) => {
  allNotificationsObject.notificationApiInfo.pendingChanges = activePrinterData?.pendingData
  const [notification, setNotification] = useState(DeliquencyNotification.NONE)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rootState = activePrinterData?.root?.state
        let notificationStatus = DeliquencyNotification.NONE
        if (rootState === 'active') {
          notificationStatus = await fetchPending(activePrinterData?.pendingData)
        } else if (rootState === 'suspended') {
          notificationStatus = DeliquencyNotification.SUSPENDED
        }

        setNotification(notificationStatus)
      } catch (error) {
        setNotification(DeliquencyNotification.NONE)
      }
    }

    fetchData()
  }, [activePrinterData?.pendingData, activePrinterData?.root])

  return notification
}

const fetchPending = async (pendingData) => {
  const hasPaymentIssueSuspended = pendingData.contents.some(
    (item) => item.type === 'paymentIssue' && item.value.state === 'suspended'
  )

  return hasPaymentIssueSuspended
    ? DeliquencyNotification.OVERDUE
    : DeliquencyNotification.NONE
}
