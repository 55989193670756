import { usePostCancelNotification } from '@/hooks/usePostCancelNotification'
import { SubscriptionStateEnum } from '@/types'
import { NavigationType } from '@/types/shell'
const GetAllPostCancelNotification = (
  subscriptionId,
  tenantId,
  mainEntity,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigation?: NavigationType,
  allNotificationsObject?: any,
  pendingData?: any
) => {
  const notificationObject = usePostCancelNotification(
    subscriptionId,
    tenantId,
    mainEntity,
    navigation,
    allNotificationsObject,
    pendingData
  )
  if (
    mainEntity?.state === SubscriptionStateEnum.CANCELED ||
    mainEntity?.state === SubscriptionStateEnum.CANCELING
  )
    return notificationObject
}

export default GetAllPostCancelNotification
