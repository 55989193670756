import tokens from '@veneer/tokens'
import styled from 'styled-components'
import { Direction } from './DescriptionList'

const { gray6, gray9 } = tokens.color
const { size0, family0, lineHeight1 } = tokens.typography
const { size1 } = tokens.layout

interface StyledDescriptionListProps {
  $direction?: Direction
}

export const StyledDescriptionList = styled.dl<StyledDescriptionListProps>`
  display: flex;
  align-items: center;
  gap: ${size1};
  ${({ $direction }) => {
    return $direction === 'column'
      ? 'flex-direction: column; align-items: flex-start;'
      : 'justify-content: space-between;'
  }}
  font-size: ${size0};
  font-family: ${family0};
  line-height: ${lineHeight1};
  color: ${gray6};
`

export const StyledTerm = styled.dt`
  margin: 0;
`

export const StyledDescription = styled.dd`
  margin: 0;
  color: ${gray9};
`
