import { useEffect, useState, useMemo } from 'react'
import { PaymentClient } from '@/services/PaymentClient'
import getAuthProvider from '@/helpers/getAuthProvider'
import getApplicationStack from '@/helpers/getApplicationStack'
export const useExpirationDate = (
  activePrinterData,
  allNotificationsObject
) => {
  const authProvider = getAuthProvider()
  const stack = getApplicationStack()
  const [paymentData, setPaymentData] = useState<any>(null)
  const [expirationDate, setExpirationDate] = useState<string>('')
  const paymentClient = useMemo(() => new PaymentClient(authProvider, stack), [
    authProvider,
    stack
  ])
  allNotificationsObject.notificationApiInfo.paymentMethod = paymentData
  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const PaymentDetails = await paymentClient.getPaymentDetails(
          activePrinterData?.root?.paymentMethodId
        )
        setPaymentData(PaymentDetails)
      } catch (error) {
        console.error('Error:', error)
      }
    }
    fetchPaymentData()
  }, [paymentClient, activePrinterData])

  useEffect(() => {
    if (paymentData?.payment_method_details?.expiration_date) {
      setExpirationDate(paymentData?.payment_method_details?.expiration_date)
    }
  }, [paymentData])

  return { expirationDate }
}
