import { SubscriptionStateEnum } from '@/types'

export const getAddPaperInfo = async (optionsData: any) => {
  const editData = optionsData?.data?.entities[0]?.edit

  const optionItem =
    Array.isArray(editData) && editData.find((item) => item?.optionId)

  const isPaperEnabled =
    Array.isArray(optionItem?.outcomes) &&
    optionItem?.outcomes[0]?.value?.productType === 'instantPaper' &&
    optionItem?.enabled === true

  const price = Array.isArray(optionItem?.outcomes)
    ? parseFloat(optionItem?.outcomes[0]?.value?.price?.cost)
    : 0

  return {
    enabled: isPaperEnabled,
    optionId: optionItem?.optionId,
    priceInfo: isNaN(price) ? 0 : price
  }
}

export const getRemovePaperEnabled = async (entities: any[]) => {
  const instantPaper = entities.filter(
    (item) =>
      item.entityType === 'instant-paper' &&
      (item.state === SubscriptionStateEnum.SUBSCRIBED ||
        item.state === SubscriptionStateEnum.PENDING)
  )

  return instantPaper.length > 0
}

export const getActivePaperSubscription = async (entities: any[]) => {
  const instantPaper = entities.filter(
    (item) =>
      item.entityType === 'instant-paper' &&
      item.state !== SubscriptionStateEnum.CANCELED
  )

  return instantPaper.length > 0
}
