import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const {
  cornerRadius2,
  xsMax,
  size6,
  size4,
  size1,
  size8,
  size5,
  size3,
  size7
} = tokens.layout
const { highContrastGray, gray12, gray9, gray6 } = tokens.color
interface MasterDeviceDetailProps {
  isDisabled?: boolean
}

export const Panel = styled.div`
  background-color: #fafafa;
  color: ${({ theme }) => theme.colorForegroundMedium};
  border-radius: ${cornerRadius2};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${xsMax}) {
    flex-direction: column;
  }
  padding: ${size6};
  margin-bottom: ${size4};
`

export const DeviceImage = styled.div<MasterDeviceDetailProps>`
  align-self: center;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  > img {
    max-width: 500px;
    min-width: 200px;
    max-height: 200px;
    @media screen and (max-width: ${xsMax}) {
      margin-top: ${size8};
      max-width: 263px;
      max-height: 220px;
    }
  }
`
export const DeviceInfo = styled.div<MasterDeviceDetailProps>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  > div {
    > svg {
      color: ${(props) => (props.isDisabled ? highContrastGray : gray12)};
    }
    > h4 {
      color: ${(props) => (props.isDisabled ? highContrastGray : gray9)};
      font-size: ${size5};
      line-height: ${size7};
    }
    &:first-child {
      margin-bottom: ${size4};
    }
  }

  > div > div {
    + span {
      display: block;
      margin: ${size1} 0 ${size3} 0;
      color: ${(props) => (props.isDisabled ? highContrastGray : gray9)};
      > svg {
        color: ${(props) => (props.isDisabled ? highContrastGray : gray9)};
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
export const ItemCode = styled.div<MasterDeviceDetailProps>`
  margin-bottom: ${size4};
  color: ${(props) => (props.isDisabled ? highContrastGray : gray6)};
`

export const ProductNo = styled.div<MasterDeviceDetailProps>`
  color: ${(props) => (props.isDisabled ? highContrastGray : '#727272')};
`
export const SerialNo = ProductNo
export const EligibleFor = ProductNo
