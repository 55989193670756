import { JarvisAuthProvider } from '@jarvis/web-http'
import {
  Stack,
  StratusClient
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { Payment } from '@/types/payment'
import { getAPIURLS } from '../../helpers/getUrlConfig'
import { getStack } from '../../helpers/getStack'

export class PaymentClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(getAPIURLS(getStack(stack)).PAYMENT_METHOD_URL, authToken)
  }

  async getPaymentStatus(): Promise<Payment> {
    return this.jarvisWebHttpInstance
      .get({
        url: '/addresssvc'
      })
      .then((response) => response.data)
  }
  async getPaymentDetails(Id): Promise<Payment> {
    return (
      Id &&
      this.jarvisWebHttpInstance
        .get({
          url: `/payment-methods/${Id}`
        })
        .then((response) => response.data)
    )
  }
}
