import useSubscriptionClient from '@/hooks/useSubscriptionClient'
import useDataFetcher from '@/hooks/useDataFetcher'

const useInstantInkSubscriptionInfo = () => {
  const client = useSubscriptionClient()
  return useDataFetcher(
    'instantInkSubscriptionInfo',
    client?.getInstantInkSubscriptionData
      ? client.getInstantInkSubscriptionData.bind(client)
      : null
  )
}

export default useInstantInkSubscriptionInfo
