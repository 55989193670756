import { useState, useEffect } from 'react'
import { DeliquencyNotification } from '@/types/pendingChanges'
import { useExpirationDate } from '../useExpirationDate'
import { getExpirationStatus } from '@/helpers/getExpirationStatus'

export const useExpirationDelinquencyNotification = (
  activePrinterData,
  notification,
  allNotificationsObject
) => {
  const [status, setStatus] = useState(null)

  const { expirationDate } = useExpirationDate(
    activePrinterData,
    allNotificationsObject
  )
  useEffect(() => {
    if (expirationDate && notification === DeliquencyNotification.NONE) {
      const expirationStatus = getExpirationStatus(expirationDate)
        ?.notificationStatus

      setStatus(expirationStatus)
    } else if (expirationDate) {
      setStatus(notification)
    }
  }, [activePrinterData, expirationDate, notification])

  return status
}
