import {
  DeviceCache,
  DeviceCacheResponse,
  RetrieveReturnResponse
} from '@/types/Subscription'
import {
  CommerceInfo,
  DeviceGrantInfo,
  InstantInkSubscriptionInfo,
  PaymentMethods,
  CancellationInfo
} from '@/types/subscription-response'
import {
  JarvisAuthProvider,
  JarvisWebHttpClientRequestOptions
} from '@jarvis/web-http'
import {
  DeviceCacheApiClient,
  GrantControllerSvcClient,
  Stack,
  ReturnMgtSvcClient
} from '@jarvis/web-stratus-client'
import axios, { AxiosInstance } from 'axios'
import { forceLoginHandling } from '@/utils/forceLoginHandling'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'
import { SubscriptionStateResponse } from '@/types/subscription-state'
import { BillingInfo } from '@/types/BillingInfo'

class SubscriptionClient {
  stack: Stack
  httpClient: AxiosInstance
  mfeAuthProvider: JarvisAuthProvider
  grantControllerClient: GrantControllerSvcClient
  deviceCacheApiClient: DeviceCacheApiClient
  returnMgtSvcClient: ReturnMgtSvcClient
  constructor(stack: Stack, authProvider: JarvisAuthProvider) {
    this.stack = stack
    this.httpClient = axios
    this.httpClient.defaults.params = {}
    this.httpClient.interceptors.response.use(null, onResponseError)
    this.mfeAuthProvider = authProvider
    this.grantControllerClient = new GrantControllerSvcClient(
      this.stack,
      this.mfeAuthProvider
    )

    this.returnMgtSvcClient = new ReturnMgtSvcClient(
      this.stack,
      this.mfeAuthProvider
    )
    this.deviceCacheApiClient = new DeviceCacheApiClient(
      this.stack,
      this.mfeAuthProvider
    )
  }

  async retrieveReturns(tenantId: string): Promise<RetrieveReturnResponse> {
    return this.returnMgtSvcClient
      .getTenantReturnDetails(tenantId)
      .then(({ data }) => {
        const returnItem = data.filter((item) => item.tenantId === tenantId)[0]
        return returnItem
      })
      .catch((err) => {
        if (err?.response?.status === 404) return err?.response?.data
        else {
          sendOpenTelemetryEvent(
            `Error while fetching returns data from customer management api : ` +
              JSON.stringify(err)
          )
          throw err
        }
      })
  }

  splitHybridSubscription(
    subscriptions: SubscriptionStateResponse[]
  ): SubscriptionStateResponse[] {
    return subscriptions.reduce((previousValue, current) => {
      const mainDevices = current.entities.filter(
        (entity) =>
          entity.entityType == 'iot-printer' ||
          entity.entityType == 'iot-pc' ||
          entity.entityType == 'chromebook'
      )
      if (mainDevices.length <= 1) {
        previousValue.push(current)
        return previousValue
      }

      mainDevices.sort((a, b) => a.entityType.localeCompare(b.entityType))
      let lastEntityType
      const splitedSubs = mainDevices.reduce(
        (accumulate, curMain, curIndex) => {
          if (curMain.entityType !== lastEntityType) {
            const newSub = { ...current }
            const validEntites = newSub.entities.filter(
              (entity) =>
                curMain.entityId === entity.entityId ||
                curMain.linkedEntities.some(
                  (linkedEntity) => linkedEntity.value === entity.entityId
                ) ||
                curMain.entityType === entity.entityType
            )
            lastEntityType = curMain.entityType

            newSub.entities = validEntites
            newSub['hybridIndex'] = curIndex
            accumulate.push(newSub)
          }
          return accumulate
        },
        [] as SubscriptionStateResponse[]
      )
      return previousValue.concat(splitedSubs)
    }, [] as SubscriptionStateResponse[])
  }

  async retrieveDeviceCaches(): Promise<DeviceCache[]> {
    const params: JarvisWebHttpClientRequestOptions = {
      params: { types: 'all' }
    }
    return this.deviceCacheApiClient
      .list(params)
      .then(({ data }) => data)
      .catch((err) => {
        sendOpenTelemetryEvent(
          `Error while fetching  data from retrieve device caches api : ` +
            JSON.stringify(err)
        )
        throw err
      })
  }

  getDeviceDetail = async (deviceId: string): Promise<DeviceCacheResponse> => {
    return this.deviceCacheApiClient
      .show(deviceId)
      .then(({ data }) => data)
      .catch((err) => {
        sendOpenTelemetryEvent(
          `Error while fetching device data from device cache api : ` +
            JSON.stringify(err)
        )
        return null
      })
  }

  async getInstantInkSubscriptionData(): Promise<
    Array<InstantInkSubscriptionInfo>
  > {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return await this.httpClient
      .get(
        `${process.env.BASE_URL_PROVIDER}/instantink/v1/commerce/subscriptions`,
        {
          headers
        }
      )
      .then(({ data }) => {
        if (
          !data?.instantInkSubscriptions ||
          !data.instantInkSubscriptions?.length
        ) {
          return []
        }
        const subscriptions = data.instantInkSubscriptions.map(
          async (subscription) => {
            return { subscription }
          }
        )
        return Promise.all(subscriptions)
      })
      .catch((err) => {
        sendOpenTelemetryEvent(
          `Error while fetching  data from instantink subscriptions api : ` +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getDeviceGrantInfo(): Promise<DeviceGrantInfo> {
    return this.grantControllerClient
      .getGrants({
        level: 'DEVICE'
      })
      .then(({ data }) => {
        const contents = data.contents
        const totalSize = data.totalSize
        return { contents, totalSize }
      })
      .catch((err) => {
        if (err?.response?.status === 404)
          return { contents: null, totalSize: null }
        else {
          sendOpenTelemetryEvent(
            `Error while fetching device grant info from grant controller api : ` +
              JSON.stringify(err)
          )
          throw err
        }
      })
  }

  async getCommerceInfo(id: string): Promise<CommerceInfo> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }
    return await this.httpClient
      .get(
        `${process.env.BASE_URL_PROVIDER}/v3/commercemgtsvc/subscriptions/${id}/details`,
        {
          headers
        }
      )
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        sendOpenTelemetryEvent(
          `Error while fetching commerce info from commerce api : ` +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getCommerceGeneralInfo(id: string) {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }
    return await this.httpClient
      .get(
        `${process.env.BASE_URL_PROVIDER}/v3/commercemgtsvc/subscriptions/${id}?fields=pmReference`,
        {
          headers
        }
      )
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        sendOpenTelemetryEvent(
          `Error while fetching subscription info from commercemgtsvc api : ` +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getPaymentMethods(pmref: string): Promise<PaymentMethods> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }
    try {
      if (!pmref) throw new Error('Payment method reference is missing')
      const { data } = await this.httpClient.get(
        `${process.env.BASE_URL_PROVIDER}/v1/payment-methods/${pmref}`,
        {
          headers
        }
      )
      return data
    } catch (error) {
      sendOpenTelemetryEvent(
        `Error while fetching payment-methods info from payment-methods api : ` +
          JSON.stringify(error)
      )
      throw error
    }
  }

  async getAllSubscriptionsState() {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    const response = await this.httpClient
      .get(
        `${process.env.BASE_URL_PROVIDER}/subscription-state/v1/subscriptions`,
        {
          headers
        }
      )
      .then(({ data }) =>
        data?.contents ? this.splitHybridSubscription(data.contents) : []
      )
      .catch((err) => {
        if (err?.response?.status === 404) return []
        else {
          sendOpenTelemetryEvent(
            `Error while fetching subscriptions data from subscription state api : ` +
              err?.message
          )
          throw err
        }
      })

    return response
  }

  async getCancellationInfo(
    sId: string,
    eId: string
  ): Promise<CancellationInfo> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return await this.httpClient
      .get(
        `${process.env.BASE_URL_PROVIDER}/masterblcontroller/v1/${process.env.ONBOOKS_BL_ID}/subscriptions/${sId}/options?entityIds=${eId}`,
        {
          headers
        }
      )
      .then(({ data }) => {
        const subscription = data.subscription
        const entities = data.entities
        return { subscription, entities }
      })
      .catch((err) => {
        if (err?.response?.status === 404)
          return { subscription: null, entities: null }
        else {
          sendOpenTelemetryEvent(
            `Error while fetching subscriptions options from mblc api : ` +
              JSON.stringify(err)
          )
          throw err
        }
      })
  }

  async getBillingData(): Promise<BillingInfo> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return this.httpClient
      .get(`${process.env.BASE_URL_PROVIDER}/instantink/v1/commerce/billing`, {
        headers
      })
      .then(({ data }) => data)
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error while fetching billing data from storefront client: ' +
            JSON.stringify(err)
        )
        throw err
      })
  }
}

export default SubscriptionClient

export const onResponseError = (error) => {
  const status = error.status || error.response.status
  if (status === 403) {
    forceLoginHandling()
    return error
  }
}
