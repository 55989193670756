import styled from 'styled-components'

export const MultipleStyledNotification = styled.div`
  padding: 0;
  width: 100%;
  > div {
    border-radius: 0;
    margin: 0;
    padding: 0px 40px;
    white-space: break-spaces;
    margin-bottom: 2px;
    align-items: center;
    > div {
      margin: 11px 12px 11px 4px;
      align-items: flex-start;
    }
  }

  .custom-link1 {
    display: inline;
    cursor: pointer;
  }

  .custom-link2 {
    display: inline;
    cursor: pointer;
  }
`
export const Title = styled.div<{ marginInfo?: boolean }>`
  font-weight: bold;
  font-size: 14px;
  align-items: flex-start;
  margin-bottom: ${(props) => (props.marginInfo ? '4px' : '0px')};
`
