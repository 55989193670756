const defaultText = {
  title: 'Paper Add-on Service has been added!',
  'sub-title':
    'You will receive a confirmation email with your plan change. Your new plan details:​',
  'shipping-text': 'Shipping to:',
  change: 'Change',
  done: 'Done'
}

export const getDefaultText = (key: string) => {
  return defaultText[key]
}

export const getPriceText = (pricePlan: string) => `$${pricePlan} printing plan`

export const getPagesText = (pagesPerMonth: string) =>
  `Includes ${pagesPerMonth} pages/month of ink and paper delivery`
