import React from 'react'
import Images from '../../../assets/images'
import useGetText from '../../../components/hooks/useGetText'
import {
  Banner,
  BannerContent,
  BannerImage,
  ButtonBlock,
  Description,
  FeatureBlock,
  FeatureText,
  NewTag,
  Title
} from './styles'
import { getDefaultText, getFeatureWithPrice } from './defaultText'
import Button from '@veneer/core/dist/scripts/button'

interface PaperProps {
  pricePlan: string
  pagesPerMonth: string
  handleButtonClick?: () => void
}

export const PaperDetails: React.FunctionComponent<PaperProps> = ({
  pricePlan,
  pagesPerMonth,
  handleButtonClick
}) => {
  const getText = useGetText('paper-info')
  return (
    <Banner>
      <NewTag>{getText('new')}</NewTag>
      <BannerImage>
        <img src={Images.PaperBannerDesktop} alt="Paper Banner Image" />
      </BannerImage>
      <BannerContent>
        <Title>
          {getText('title', { defaultText: getDefaultText('title') })}
        </Title>
        <Description>
          {getText('description', {
            defaultText: getDefaultText('description')
          })}
        </Description>
        <FeatureBlock>
          <p>&bull;</p>
          <FeatureText>
            {getText('features.one', {
              defaultText: getDefaultText('featureOne')
            })}
          </FeatureText>
        </FeatureBlock>
        <FeatureBlock>
          <p>&bull;</p>
          <FeatureText>
            {getText('features.two', {
              defaultText: getDefaultText('featureTwo')
            })}
          </FeatureText>
        </FeatureBlock>
        <FeatureBlock>
          <p>&bull;</p>
          <FeatureText>
            {getText('features.three', {
              pricePlan,
              pagesPerMonth,
              defaultText: getFeatureWithPrice(pricePlan, pagesPerMonth)
            })}
          </FeatureText>
        </FeatureBlock>
        <ButtonBlock>
          <Button appearance="primary" onClick={handleButtonClick}>
            {getText('btnLabel', {
              defaultText: getDefaultText('btnLabel')
            })}
          </Button>
        </ButtonBlock>
      </BannerContent>
    </Banner>
  )
}
