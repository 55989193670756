import React, { useState } from 'react'
import Markdown from 'markdown-to-jsx'
import { MultipleStyledNotification, Title } from './styles'
import InlineNotification from '@veneer/core/dist/scripts/inline_notification/inline_notification'
import { NotificationType } from 'src/types/delinquencyNotification'
import { ENotification } from './helper'
export interface NotificationData {
  title?: string
  description?: string
  notificationID?: string
  showCloseButton?: boolean
  notificationType?: NotificationType
  callbackFunction?: () => void | (Window & typeof globalThis)
  SecondCallbackFunction?: () => void | (Window & typeof globalThis)
}

export interface Props {
  multiNotificationArray: NotificationData[]
}

export const getNotificationIndex = (
  type: NotificationType | undefined
): number => {
  switch (type) {
    case ENotification.NEGATIVE:
      return 0
    case ENotification.WARNING:
      return 1
    case ENotification.INFORMATIVE:
      return 2
    case ENotification.POSITIVE:
      return 3
    default:
      return -1
  }
}

const CustomLink1 = ({ children, callbackFunction }: any) => (
  <span className="custom-link1 link" onClick={callbackFunction}>
    {children}
  </span>
)

const CustomLink2 = ({ children, SecondCallbackFunction }: any) => (
  <a className="custom-link2 link" onClick={SecondCallbackFunction}>
    {children}
  </a>
)

export function MultipleNotification({
  multiNotificationArray
}: Readonly<Props>) {
  const [closedNotifications, setClosedNotifications] = useState<string[]>([])

  const handleCloseNotification = (notificationID: string) => {
    setClosedNotifications([...closedNotifications, notificationID])
  }

  const isNotificationClosed = (notificationID: string) => {
    return closedNotifications.includes(notificationID)
  }

  const getFormattedDescription = (
    description: string,
    callbackFunction?: () => void | (Window & typeof globalThis),
    SecondCallbackFunction?: () => void | (Window & typeof globalThis)
  ) => {
    return (
      description && (
        <Markdown
          options={{
            overrides: {
              span: {
                component: CustomLink1,
                props: { callbackFunction }
              },
              nav: {
                component: CustomLink2,
                props: { SecondCallbackFunction }
              }
            }
          }}
        >
          {description}
        </Markdown>
      )
    )
  }

  const sortedNotifications = multiNotificationArray.sort((a, b) => {
    return (
      getNotificationIndex(a.notificationType) -
      getNotificationIndex(b.notificationType)
    )
  })

  const renderNotification = (data: NotificationData | undefined) => {
    if (!data) return null

    const {
      title,
      description,
      notificationID,
      notificationType,
      showCloseButton,
      callbackFunction,
      SecondCallbackFunction
    } = data
    const marginInfo = description ? true : false
    return (
      !isNotificationClosed(notificationID) && (
        <InlineNotification
          key={notificationID}
          id={notificationID}
          closeButton={showCloseButton}
          type={notificationType}
          title={<Title marginInfo={marginInfo}>{title}</Title>}
          description={getFormattedDescription(description, callbackFunction, SecondCallbackFunction)}
          onClose={() => handleCloseNotification(notificationID)}
        />
      )
    )
  }

  return (
    <MultipleStyledNotification>
      {sortedNotifications.map((notificationData) =>
        renderNotification(notificationData)
      )}
    </MultipleStyledNotification>
  )
}
