import styled, { css } from 'styled-components'
import tokens from '@veneer/tokens'

export interface ContainerProps {
  $position?: 'absolute' | null
}

export const CenterContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  ${({ $position }) =>
    $position === 'absolute'
      ? `position: absolute;
    background-color: ${tokens.color.white};
    top: 0;
    left: 0;`
      : null}
`

const sizeHandler = ({ $size }) => {
  const fontSize = `fontSize${$size}`
  const lineHeight = `lineHeight${$size + 1}`

  return css`
    font-size: ${tokens[fontSize]};
    line-height: ${tokens[lineHeight]};
  `
}

export const Text = styled.p<{ $size: number }>`
  ${sizeHandler}
  margin-top: ${tokens.layout.size2};
  text-align: center;
`
