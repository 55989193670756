import React from 'react'

import { AssetsProvider } from '@jarvis/web-assets-provider'
import getLocation from '../../utils/location'
import AssetsProviderFactory from '@/assets/AssetsProviderFactory'

const useAssetsProvider = () => {
  const [
    assetsProvider,
    setStateAssetsProvider
  ] = React.useState<AssetsProvider | null>()

  React.useEffect(() => {
    const { language = 'en', country = 'US' } = getLocation()
    if (language && country) {
      const assetsProviderFactory = AssetsProviderFactory.create(
        language,
        country
      )

      setStateAssetsProvider(() => assetsProviderFactory)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return assetsProvider
}

export default useAssetsProvider
