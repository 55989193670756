import tokens from '@veneer/tokens'
import styled from 'styled-components'

const { gray9 } = tokens.color

const { size2 } = tokens.layout

export const Divisor = styled.div`
  margin-bottom: ${size2};
`

export const TextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${gray9};
`
