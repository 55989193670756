import { DefaultExportProps } from 'src/types'
import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { hpBlue7 } = tokens.color
const { size0, family0, lineHeight1 } = tokens.typography

export type LinkProps = Partial<DefaultExportProps>

export const Link = styled.a<LinkProps>`
  font-size: ${size0};
  font-family: ${family0};
  line-height: ${lineHeight1};
  margin: 0;
  color: ${hpBlue7};
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
  &:visited,
  &:link {
    color: ${hpBlue7};
  }
`
