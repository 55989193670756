import useGetText from '../../hooks/useGetText/useGetText'
import { NotificationType, NotificationID } from '../../utils/common-utils'
import { useGetDeviceClient } from '../../hooks/useGetDeviceClient'
import defaultLanguageJson from '../../assets/locale/en_US.json'
const GetAllOfflineNotification = (mfe, printer, allNotificationsObject) => {
  const getText = useGetText('offlineNotifications')
  const entityState = printer?.state
  const uniqueDeviceId = printer?.entityDetails?.uniqueDeviceId
  const deviceData = useGetDeviceClient(uniqueDeviceId, entityState)
  const deviceState = deviceData?.status?.connectionState
  const isDeviceOffline = deviceState === 'offline'
  allNotificationsObject.notificationApiInfo.deviceConnectionState = deviceState
  allNotificationsObject.notificationApiInfo.deviceCacheData = deviceData
  if (isDeviceOffline) {
    switch (mfe) {
      case 'Trackshipments':
        return {
          title: getText('shipmemtTracking.title', {
            defaultValue:
              defaultLanguageJson.offlineNotifications.shipmemtTracking.title
          }),
          description: getText('shipmemtTracking.description', {
            defaultValue:
              defaultLanguageJson.offlineNotifications.shipmemtTracking
                .description
          }),
          notificationID: NotificationID.SHIPMENTTRACKING_OFFLINE,
          showCloseButton: true,
          notificationType: NotificationType.WARNING
        }
      case 'overview':
        return {
          title: getText('overviewPage.title', {
            defaultValue:
              defaultLanguageJson.offlineNotifications.overviewPage.title
          }),
          description: getText('overviewPage.description', {
            defaultValue:
              defaultLanguageJson.offlineNotifications.overviewPage.description
          }),
          notificationID: NotificationID.OVERVIEW_OFFLINE,
          showCloseButton: false,
          notificationType: NotificationType.INFORMATIVE
        }
      default:
        return null
    }
  }
}

export default GetAllOfflineNotification
