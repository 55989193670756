import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { gray12, gray9 } = tokens.color
const { size1, size0, lineHeight1, lineHeight2 } = tokens.typography

export const NotificationBarContainer = styled.div`
  && > div {
    padding: 14px 40px;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    & > svg {
      margin-top: 0;
      margin-right: 14px;
    }
    .vn-notification__description {
      max-width: 990px;
    }
    .vn-notification__text {
      margin: 0;
      padding-top: 4px;
      span:nth-of-type(1) {
        font-weight: 700;
        font-size: ${size1};
        line-height: ${lineHeight2};

        color: ${gray12};
        padding-bottom: 4px;
      }
      span:nth-of-type(2) {
        font-weight: 400;
        font-size: ${size0};
        line-height: ${lineHeight1};

        color: ${gray9};
      }
    }
    .vn-notification__close {
      align-self: center;
    }
  }
`
