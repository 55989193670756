import { JarvisAuthProvider } from '@jarvis/web-http'
import {
  Stack,
  StratusClient
} from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { AxiosResponse } from 'axios'
import { getStack } from '../../helpers/getStack'
import { getAPIURLS } from '../../helpers/getUrlConfig'
import { Subscription, SubscriptionEntityUpdate } from 'src/types'
import { SubscriptionOptionsResponse } from 'src/types/subscription.options'

export class SubscriptionsClient extends StratusClient {
  timeout = 0

  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(getAPIURLS(getStack(stack)).MBLC_BASE_URL, authToken)
  }

  getSubscriptions(blId: string): Promise<AxiosResponse<Subscription[]>> {
    return this.jarvisWebHttpInstance.get({
      url: `/${blId}/subscriptions`,
      timeout: this.timeout
    })
  }

  getSubscriptionOptions(
    subscriptionId: string,
    blId: string,
    entityId?: string
  ): Promise<AxiosResponse<SubscriptionOptionsResponse>> {
    let url = `/${blId}/subscriptions/${subscriptionId}/options`
    if (entityId) {
      url += `?entityIds=${entityId}`
    }
    return this.jarvisWebHttpInstance.get({
      url: url,
      timeout: this.timeout
    })
  }

  update(
    subscriptionId: string,
    blId: string,
    data: SubscriptionEntityUpdate
  ): Promise<AxiosResponse<SubscriptionOptionsResponse>> {
    return this.jarvisWebHttpInstance.patch({
      url: `/${blId}/subscriptions/${subscriptionId}`,
      timeout: this.timeout,
      data
    })
  }
}
