export const navigationLinks = {
  overview: '/ucde/hpone-printer/overview',
  plans: '/ucde/hpone-printer/update-plan',
  history: '/ucde/hpone-printer/print-history',
  shipment: '/ucde/hpone-printer/shipment-tracking',
  orders: '/ucde/account-details/orders',
  shippingInformation: '/ucde/account-details/shipping-billing',
  subscriptionsInformation: '/ucde/account-details/subscriptions',
  myPrinters: '/ucde/my-printers',
  billingInformation: '/ucde/account-details/billing-information', // DEPRECATED
  terms: 'https://instantink-stage1.hpconnectedstage.com/us/en/v2/terms',
  hpOneTerms: 'https://www.hp.com/us-en/all-in-plan/terms.html',
  citizensBankAuth: 'https://pay.citizensbank.com/auth/login',
  supportTel: '1-855-785-2777',
  instantInk: 'https://www.hpinstantink.com',
  suppliesRecycling:
    'https://www.hp.com/us-en/hp-information/recycling/ink-toner.html',
  appStoreLink: 'https://apps.apple.com/app/hp-smart/id469284907',
  microsoftStoreLink: 'https://www.microsoft.com/en-us/p/hp-smart/9wzdncrfhwlh',
  playStoreLink:
    'https://play.google.com/store/apps/details?id=com.hp.printercontrol',
  macAppStoreLink: 'https://apps.apple.com/app/hp-smart/id1474276998'
}

export const navigationLinksHpAllIn = {
  overview: '/ucde/hp-all-in-print-plan/overview',
  plans: '/ucde/hp-all-in-print-plan/update-plan',
  history: '/ucde/hp-all-in-print-plan/print-history',
  shipment: '/ucde/hp-all-in-print-plan/shipment-tracking',
  orders: '/ucde/account-details/orders',
  shippingInformation: '/ucde/account-details/shipping-billing',
  subscriptionsInformation: '/ucde/account-details/subscriptions',
  myPrinters: '/ucde/my-printers',
  billingInformation: '/ucde/account-details/billing-information', // DEPRECATED
  terms: 'https://instantink-stage1.hpconnectedstage.com/us/en/v2/terms',
  hpOneTerms: 'https://www.hp.com/us-en/hp-one/terms.html',
  citizensBankAuth: 'https://pay.citizensbank.com/auth/login',
  supportTel: '1-855-785-2777',
  instantInk: 'https://www.hpinstantink.com',
  suppliesRecycling:
    'https://www.hp.com/us-en/hp-information/recycling/ink-toner.html',
  appStoreLink: 'https://apps.apple.com/app/hp-smart/id469284907',
  microsoftStoreLink: 'https://www.microsoft.com/en-us/p/hp-smart/9wzdncrfhwlh',
  playStoreLink:
    'https://play.google.com/store/apps/details?id=com.hp.printercontrol',
  macAppStoreLink: 'https://apps.apple.com/app/hp-smart/id1474276998'
}
