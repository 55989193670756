import React from 'react'
import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { gray9 } = tokens.color
const { size3, size4, family0, lineHeight3, lineHeight4 } = tokens.typography

export interface CardTitleProps {
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  className?: string
}

const StyledCardTitle = styled.p<CardTitleProps>`
  font-size: ${size3};
  font-family: ${family0};
  line-height: ${lineHeight3};
  font-weight: 400;
  margin: 0;
  color: ${gray9};
  @media (min-width: 1280px) {
    font-size: ${size4};
    line-height: ${lineHeight4};
  }
`

export const CardTitle: React.FunctionComponent<CardTitleProps> = ({
  as,
  className,
  children
}) => {
  return (
    <StyledCardTitle as={as} className={className}>
      {children}
    </StyledCardTitle>
  )
}
